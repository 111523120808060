import { Outlet } from 'react-router-dom';
import AppLayout from 'layouts/AppLayout';

const WidgetsPage = () => {
  return (
    <AppLayout>
        <Outlet/>
    </AppLayout>
  )
}

export default WidgetsPage; 