import React, { useState, useEffect } from 'react';

const sampleData: any = {
  gold: {
    livePrices: {
      USD: { price: 2050.30, change: 15.20, changePercent: 0.75 },
      AUD: { price: 3125.45, change: 22.80, changePercent: 0.73 },
      CAD: { price: 2765.20, change: 20.15, changePercent: 0.73 },
      CHF: { price: 1800.15, change: 12.50, changePercent: 0.70 },
      CNY: { price: 13250.75, change: 95.60, changePercent: 0.73 },
      EUR: { price: 1875.60, change: 13.80, changePercent: 0.74 },
      GBP: { price: 1620.40, change: 11.90, changePercent: 0.74 },
      INR: { price: 152000.50, change: 1120.30, changePercent: 0.74 },
      JPY: { price: 230500.25, change: 1680.50, changePercent: 0.73 }
    },
    annualChanges: {
      2024: { USD: 421.6, AUD: 624.4, CAD: 559.9, CHF: 325.4, CNY: 2731.1, EUR: 319.9, GBP: 298.8, INR: 27225.5, JPY: 48760.6 },
      2023: { USD: 212.8, AUD: 312.6, CAD: 219.9, CHF: 42.4, CNY: 1657.7, EUR: 149.7, GBP: 96.6, INR: 17134.4, JPY: 39616.6 },
      2022: { USD: -6.3, AUD: 165.5, CAD: 159.9, CHF: 18.0, CNY: 983.3, EUR: 106.0, GBP: 178.8, INR: 14070.7, JPY: 26134.4 },
      2021: { USD: -73.5, AUD: 56.2, CAD: -94.1, CHF: -36.0, CNY: -731.1, EUR: 51.9, GBP: -37.5, INR: -2096.6, JPY: 14575.5 },
      2020: { USD: 514.6, AUD: 346.6, CAD: 502.2, CHF: 252.0, CNY: 1989.9, EUR: 254.4, GBP: 319.9, INR: 36279.9, JPY: 35785.5 }
    }
  },
  silver: {
    livePrices: {
      USD: { price: 23.50, change: 0.35, changePercent: 1.51 },
      AUD: { price: 35.75, change: 0.52, changePercent: 1.48 },
      CAD: { price: 31.60, change: 0.46, changePercent: 1.48 },
      CHF: { price: 20.80, change: 0.30, changePercent: 1.46 },
      CNY: { price: 152.25, change: 2.20, changePercent: 1.47 },
      EUR: { price: 21.45, change: 0.31, changePercent: 1.47 },
      GBP: { price: 18.60, change: 0.27, changePercent: 1.47 },
      INR: { price: 1740.30, change: 25.30, changePercent: 1.48 },
      JPY: { price: 2645.50, change: 38.50, changePercent: 1.48 }
    },
    annualChanges: {
      2024: { USD: 3.6, AUD: 5.4, CAD: 4.9, CHF: 2.4, CNY: 23.1, EUR: 2.9, GBP: 2.8, INR: 272.5, JPY: 487.6 },
      2023: { USD: 2.8, AUD: 3.6, CAD: 2.9, CHF: 0.4, CNY: 17.7, EUR: 1.7, GBP: 1.6, INR: 174.4, JPY: 396.6 },
      2022: { USD: -1.3, AUD: 1.5, CAD: 1.9, CHF: 0.8, CNY: 9.3, EUR: 1.0, GBP: 1.8, INR: 140.7, JPY: 261.4 },
      2021: { USD: -3.5, AUD: 0.2, CAD: -4.1, CHF: -1.0, CNY: -7.1, EUR: 0.9, GBP: -1.5, INR: -20.6, JPY: 145.5 },
      2020: { USD: 4.6, AUD: 3.6, CAD: 5.2, CHF: 2.0, CNY: 19.9, EUR: 2.4, GBP: 3.9, INR: 362.9, JPY: 357.5 }
    }
  },
  // ... (similar structure for platinum, palladium)
};

const currencies = ['USD', 'AUD', 'CAD', 'CHF', 'CNY', 'EUR', 'GBP', 'INR', 'JPY'];
const years = [2024, 2023, 2022, 2021, 2020];

export default function MetalPriceWidget() {
  const [selectedMetal, setSelectedMetal] = useState('gold');
  const [showPercentage, setShowPercentage] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const data = sampleData[selectedMetal];

  useEffect(() => {
    const timer = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const formatChange = (change: any, changePercent: any, isPercentage: boolean) => {
    const value = isPercentage ? changePercent : change;
    const prefix = value >= 0 ? '+' : '';
    return `${prefix}${value.toFixed(2)}${isPercentage ? '%' : ''}`;
  };

  return (
    <div style={{ padding: '1rem', maxWidth: '1200px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <select 
          value={selectedMetal} 
          onChange={(e) => setSelectedMetal(e.target.value)}
          style={{ 
            padding: '0.5rem', 
            fontSize: '1rem', 
            backgroundColor: '#f0f0f0', 
            color: '#333', 
            border: '1px solid #ccc', 
            borderRadius: '4px', 
            cursor: 'pointer' 
          }}
        >
          <option value="gold">Gold Price</option>
          <option value="silver">Silver Price</option>
          <option value="platinum">Platinum Price</option>
          <option value="palladium">Palladium Price</option>
        </select>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <span>Value</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={showPercentage}
              onChange={() => setShowPercentage(!showPercentage)}
            />
            <span className="slider round"></span>
          </label>
          <span>Percentage</span>
        </div>
      </div>
      
      <h2 style={{ textAlign: 'center', margin: '1rem 0' }}>{selectedMetal.charAt(0).toUpperCase() + selectedMetal.slice(1)} Price Performance: {showPercentage ? '% ' : ''}Annual Change</h2>
      
      <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white' }}>
        <thead>
          <tr style={{ backgroundColor: '#f8f8f8' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Year</th>
            {currencies.map(currency => (
              <th key={currency} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                {currency}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px', fontWeight: 'bold' }}>Live Price</td>
            {currencies.map(currency => (
              <td key={currency} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                <div style={{ fontWeight: 'bold' }}>{data.livePrices[currency].price.toFixed(2)}</div>
                <div style={{ color: data.livePrices[currency].change >= 0 ? 'green' : 'red' }}>
                  {formatChange(data.livePrices[currency].change, data.livePrices[currency].changePercent, showPercentage)}
                </div>
              </td>
            ))}
          </tr>
          {years.map((year, index) => (
            <tr key={year} style={{ backgroundColor: index % 2 === 0 ? '#f8f8f8' : 'white' }}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{year}</td>
              {currencies.map(currency => {
                const value = data.annualChanges[year][currency];
                const percentage = (value / (data.livePrices[currency].price - value) * 100);
                return (
                  <td key={currency} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                    <span style={{ color: value >= 0 ? 'green' : 'red' }}>
                      {formatChange(value, percentage, showPercentage)}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      
      <div style={{ marginTop: '1rem', fontSize: '0.875rem', color: '#666', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <a href="https://www.kitco.com" target="_blank" rel="noopener noreferrer" style={{ color: '#666', textDecoration: 'none' }}>
          Powered by Kitco.com
        </a>
        <span>
          {currentDateTime.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'America/New_York'
          })} NY Time
        </span>
      </div>
      
      <style>{`
        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: .4s;
          border-radius: 34px;
        }
        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: .4s;
          border-radius: 50%;
        }
        input:checked + .slider {
          background-color: #2196F3;
        }
        input:checked + .slider:before {
          transform: translateX(26px);
        }
      `}</style>
    </div>
  );
}
