import ApiService from 'api/apiService';
import { shortSymbol } from 'common/constants';
import { getNewYorkFormattedDate, getThreeDates } from '../components/utils';
import React, { useState, useEffect } from 'react';

interface LondonFixProps {
  config: WidgetConfig; 
}

const LondonFixWidget = ({config}: LondonFixProps) => {
  const [prices, setPrices] = useState<any>(null);

  const [dates, setDates] = useState({
    '1': '',
    '2': '',
    '3': ''
  });


  const getDataForDays = async (
    daysToFetch: number = 3
  ): Promise<any[]> => {
    const results: any[] = [];
    let currentDate = new Date();
    
    while (results.length < daysToFetch) {
      // const formattedDate = currentDate.toISOString().split('T')[0]; 
      const formattedDate = getNewYorkFormattedDate(currentDate); 
      console.log('dateNY', formattedDate)
      try {
        const result = await ApiService.getLFValue(formattedDate);
        results.push({ date: formattedDate, data: result });
      } catch (error) {
        console.error(`Error fetching data for ${formattedDate}:`, error);
      }

      currentDate.setDate(currentDate.getDate() - 1);
  
      if (currentDate < new Date('2023-01-01')) {
        console.warn('Exceeded date limit, breaking the loop.');
        break;
      }
    }
  
    return results;
  };  

  const getDataHandler = async () => {
    try {
      const result = await getDataForDays(); 
      setDates({
        '1': formatDate(result[0].date),
        '2': formatDate(result[1].date),
        '3': formatDate(result[2].date)
      });
      console.log('date----', new Date(result[0].date))

      const transformed_1 = transformDataHandler(result[0].data);
      const transformed_2 = transformDataHandler(result[1].data); 
      const transformed_3 = transformDataHandler(result[2].data);  

      const newData = {
        '1': transformed_1, 
        '2': transformed_2, 
        '3': transformed_3, 
      };
      setPrices(newData); 
      console.log('result', result); 
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

/*   const getData = async () => {
    try {
      
      const dates = getThreeDates(); 
      setDates({
        '1': formatDate(new Date(dates[0])),
        '2': formatDate(new Date(dates[1])),
        '3': formatDate(new Date(dates[2]))
      });
      const response_1 = await ApiService.getLFValue(dates[0]); 
      const response_2 = await ApiService.getLFValue(dates[1]); 
      const response_3 = await ApiService.getLFValue(dates[2]); 

      const transformed_1 = transformDataHandler(response_1);
      const transformed_2 = transformDataHandler(response_2); 
      const transformed_3 = transformDataHandler(response_3);  

      const newData = {
        '1': transformed_1, 
        '2': transformed_2, 
        '3': transformed_3, 
      };
      setPrices(newData); 
    } catch (error) {
      console.log('error', error); 
    }
  } */

  const transformDataHandler = (data: LFResponse): any => {
    const newData: any = {gold: {}, silver: {}, platinum: {}, palladium: {}}; 
    data.LFValues.LFValue.forEach(item => {
      if(item.PM) {
        newData[shortSymbol[item.Symbol]].pm = item.PM; 
      }
      if(item.AM) {
        newData[shortSymbol[item.Symbol]].am = item.AM; 
      }
    });   
    return newData; 
  }

  useEffect(() => {
    // getData(); 
    getDataHandler(); 
  }, []);

/*    const formatDate = (date: any) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  };  */  

  const formatDate = (dateString: string) => {
    // Define an array for month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Split the input 'YYYY-MM-DD' string to get year, month, and day
    const [year, month, day] = dateString.split('-');
  
    // Convert the month from 'MM' to a month name using the months array
    const formattedMonth = months[parseInt(month, 10) - 1];
  
    // Return the formatted date as 'Oct 16, 2024'
    return `${formattedMonth} ${day}, ${year}`;
  };
  

  const PriceCell = ({ am, pm }: any) => (
    <td className="py-2 px-4 border text-center">
      <span className="text-xs font-semibold">AM</span> {am.toFixed(2)}
      <br />
      <span className="text-xs font-semibold">PM</span> {pm.toFixed(2)}
    </td>
  );

  const SilverPriceCell = ({ am }: any) => (
    <td className="py-2 px-4 border text-center">{am.toFixed(4)}</td>
  );

  return (
    <div className={`p-4 rounded-lg shadow-md overflow-x-scroll ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-100 text-gray-800'}`} style={{width: config?.width ?? ''}}>
      <h2 className="text-xl font-bold mb-4">London Fix</h2>
      <p className="text-sm mb-2">Please press reload or refresh for today's prices</p>
      <table className="w-full border-collapse mb-4">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-200 border">www.kitco.com</th>
            <th className="py-2 px-4 bg-yellow-200 border">GOLD</th>
            <th className="py-2 px-4 bg-gray-300 border">SILVER</th>
            <th className="py-2 px-4 bg-gray-400 border">PLATINUM</th>
            <th className="py-2 px-4 bg-gray-500 border">PALLADIUM</th>
          </tr>
        </thead>
        {prices && <tbody>
          <tr>
            <td className="py-2 px-4 border">{dates['1']}</td>
            <PriceCell {...prices['1'].gold} />
            <SilverPriceCell {...prices['1'].silver} />
            <PriceCell {...prices['1'].platinum} />
            <PriceCell {...prices['1'].palladium} />
          </tr>
          <tr>
            <td className="py-2 px-4 border">{dates['2']}</td>
            <PriceCell {...prices['2'].gold} />
            <SilverPriceCell {...prices['2'].silver} />
            <PriceCell {...prices['2'].platinum} />
            <PriceCell {...prices['2'].palladium} />
          </tr>
          <tr>
            <td className="py-2 px-4 border">{dates['3']}</td>
            <PriceCell {...prices['3'].gold} />
            <SilverPriceCell {...prices['3'].silver} />
            <PriceCell {...prices['3'].platinum} />
            <PriceCell {...prices['3'].palladium} />
          </tr>
        </tbody>}
      </table>
      <div className="text-center text-sm text-gray-600">
        Powered by <a href="https://www.kitco.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Kitco.com</a>
      </div>
    </div>
  );
};

export default LondonFixWidget;
