import { Coins } from 'lucide-react'
import { Instrument, VisualizationType, ChartPeriod } from './types'

export const instruments: Instrument[] = [
  { id: 'gold', name: 'Gold', icon: Coins, apiType: 'precious' },
  { id: 'silver', name: 'Silver', icon: Coins, apiType: 'precious' },
  { id: 'platinum', name: 'Platinum', icon: Coins, apiType: 'precious' },
  { id: 'palladium', name: 'Palladium', icon: Coins, apiType: 'precious' },
  { id: 'oil', name: 'Oil', icon: Coins, apiType: 'oil' },
  { id: 'copper', name: 'Copper', icon: Coins, apiType: 'base' },
  { id: 'nickel', name: 'Nickel', icon: Coins, apiType: 'base' },
  { id: 'aluminum', name: 'Aluminum', icon: Coins, apiType: 'base' },
  { id: 'zinc', name: 'Zinc', icon: Coins, apiType: 'base' },
  { id: 'lead', name: 'Lead', icon: Coins, apiType: 'base' },
]

export const visualizationTypes: VisualizationType[] = [
  { id: 'text', name: 'Text' },
  { id: 'bubbles', name: 'Bubbles' },
  { id: 'pieCharts', name: 'Pie Chart' },
  { id: 'charts', name: 'Charts' }
]

export const chartPeriods: ChartPeriod[] = [
  { id: 'realTime', name: 'Real Time' },
  { id: '30days', name: '30 Days' },
  { id: '60days', name: '60 Days' },
  { id: '6months', name: '6 Month' },
  { id: '1year', name: '1 Year' },
  { id: '5years', name: '5 Years' },
  { id: '10years', name: '10 Years' },
]
