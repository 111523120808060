import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import logo from '../assets/icons/kitco-logo.svg'; 
import ApiService from 'api/apiService';
import { shortSymbol, unitsNames } from 'common/constants';
import clsx from 'clsx';

interface CalculatorWidgetProps {
  config: WidgetConfig; 
}

const FinalKitcoPriceCalculator = ({config}: CalculatorWidgetProps) => {
  const [quantity, setQuantity] = useState(1);
  const [instrument, setInstrument] = useState('Gold');
  const [unit, setUnit] = useState('oz');
  const [currency, setCurrency] = useState('USD');
  const [price, setPrice] = useState<any>(null);
  const [displayPrice, setDisplayPrice] = useState<any>('');
  const [listMetals, setListMetals] = useState<BaseDataI[]>([]); 
  const [change, setChange] = useState<any>(null);
  const [changePercent, setChangePercent] = useState<any>(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLive, setIsLive] = useState(true);
  const [isSm, setIsSm] = useState(false); 

  const instruments = ['Gold', 'Silver', 'Platinum', 'Palladium'];
  const units = ['oz', 'g', 'kg'];
  const currencies = ['USD', 'CAD', 'INR', 'AUD', 'EUR', 'GBP', 'JPY', 'CHF', 'ZAR', 'CNY', 'BRL', 'MXN', 'HKD', 'RUB'];
  const intervalRef = useRef<any>(null);
  const basePrice = useRef<any>(2504.11);

  const updateStateHandler = (data: BaseDataI[], instrument: string) => {
    const currentMetal = data.find(item => shortSymbol[item.Symbol] === instrument.toLowerCase()); 
    setPrice(currentMetal?.Bid ?? 0);
    setDisplayPrice(currentMetal?.Bid.toFixed(2) ?? '');
    setChange(currentMetal?.Change);
    setChangePercent(currentMetal?.ChangePercentage);
    basePrice.current = currentMetal?.Bid;
  }

  const getData = async (currency: string, unit: string) => {
    try {
      const response = await ApiService.getPMWithParams(currency, unitsNames[unit]);
      setListMetals(response.PreciousMetals.PM); 
      updateStateHandler(response.PreciousMetals.PM, instrument); 
      setCurrentTime(new Date());
    } catch (error) {
      console.log('error', error); 
    }
  }

  const simulatePriceChange = () => {
    if (isLive) {
      const newPrice = basePrice.current + (Math.random() - 0.5) * 10;
      const newChange = newPrice - basePrice.current;
      const newChangePercent = (newChange / basePrice.current) * 100;

      setPrice(newPrice);
      setDisplayPrice(newPrice.toFixed(2));
      setChange(newChange);
      setChangePercent(newChangePercent);
    }
    setCurrentTime(new Date());
  };

/*   useEffect(() => {
    simulatePriceChange();
    intervalRef.current = setInterval(simulatePriceChange, 20000);
    return () => clearInterval(intervalRef.current);
  }, [isLive]); */

  useEffect(() => {
    getData(currency, unit); 
  }, [currency, unit, isLive]); 

  useEffect(() => {
    if(config?.width && config.width.toString().includes('px')) {
      const result = parseInt(config?.width.toString(), 10); 
      setIsSm(result < 350); 
    } else {
      setIsSm(false);
    }
  }, [config.width]); 

  useEffect(() => {
    if(listMetals.length) {
      updateStateHandler(listMetals, instrument); 
    }
  }, [instrument, listMetals]); 

  const handleQuantityChange = (e: any) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setQuantity(value);
    }
  };

  const handlePriceChange = (e: any) => {
    const value = e.target.value;
    setDisplayPrice(value);
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        setPrice(numericValue);
        setIsLive(false);
        const newChange = numericValue - basePrice.current;
        setChange(newChange);
        setChangePercent((newChange / basePrice.current) * 100);
      }
    }
  };

  const handlePriceBlur = () => {
    setDisplayPrice(price.toFixed(2));
  };

  const formatPrice = (value: any) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const calculatePrice = () => {
    let basePrice = price * quantity;
    // if (unit === 'g') basePrice /= 31.1034768;
    // if (unit === 'kg') basePrice *= 32.1507466;
    return formatPrice(basePrice);
  };

  const toggleLivePrice = () => {
    setIsLive(!isLive);
    if (!isLive) {
      // simulatePriceChange();
      getData(currency, unit); 
    }
  };

  return (
    <div className={`font-sans w-[400px] border border-gray-300 shadow-lg rounded-lg overflow-hidden ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{width: config?.width ?? '100%'}}>
      <div className="bg-gray-100 p-4 border-b border-gray-300 flex justify-between items-center">
        <img src={logo} alt="logo" className={`mr-2 ${isSm ? 'h-[15px]' : 'h-[20px]'}`} />
        <h2 className={clsx("font-bold text-center flex-grow", {
          'text-xl': !isSm, 
          'text-[16px]': isSm
        })}>{instrument} Calculator</h2>
        <button 
          onClick={toggleLivePrice} 
          className={`w-6 h-6 rounded-full transition-colors duration-300 flex items-center justify-center ${
            isLive ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
          }`}
          title={isLive ? "Press to pause live prices" : "Press to resume live prices"}
        >
          <div className={`w-4 h-4 rounded-full ${isLive ? 'bg-green-300' : 'bg-red-300'} ${isLive ? 'animate-pulse' : ''}`}></div>
        </button>
      </div>
      <div className={`${isSm ? 'p-2 text-[12px]' : 'p-4'}`}>
        <div className="flex items-center mb-4">
          <input
            type="text"
            value={quantity}
            onChange={handleQuantityChange}
            className={clsx("border border-gray-300 rounded mr-2 text-gray-800", {
              "w-20 p-2": !isSm,
              "w-10 p-1": isSm
            })}
          />
          <div className={"relative inline-block w-20 mr-2"}>
            <select
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              className={`w-full ${isSm ? 'p-1' : 'p-2'} border border-gray-300 rounded appearance-none pr-8 text-gray-800`}
            >
              {units.map(u => <option key={u} value={u}>{u}</option>)}
            </select>
            <ChevronDown size={20} className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" />
          </div>
          <div className="relative inline-block flex-grow mr-2">
            <select
              value={instrument}
              onChange={(e) => setInstrument(e.target.value)}
              className={`w-full ${isSm ? 'p-1' : 'p-2'} border border-gray-300 rounded appearance-none pr-8 text-gray-800`}
            >
              {instruments.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
            <ChevronDown size={20} className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" />
          </div>
          <div className="relative inline-block w-24">
            <select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className={`w-full ${isSm ? 'p-1' : 'p-2'} border border-gray-300 rounded appearance-none pr-8 text-gray-800`}
            >
              {currencies.map(c => <option key={c} value={c}>{c}</option>)}
            </select>
            <ChevronDown size={20} className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" />
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="font-semibold">Price:</span>
          <input
            type="text"
            value={displayPrice}
            onChange={handlePriceChange}
            onBlur={handlePriceBlur}
            className={`font-bold text-right ${isSm ? 'w-24 text-[16px]' : 'w-32 text-xl'} p-1 border border-gray-300 rounded text-gray-800`}
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="font-semibold">Total:</span>
          <span className={`${isSm ? 'text-[16px]' : "text-xl"} font-bold`}>{calculatePrice()}</span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="font-semibold">Change:</span>
          <div>
            <span className={`font-bold ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              {formatPrice(change)}
            </span>
            <span className={`ml-2 ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              ({changePercent >= 0 ? '+' : ''}{changePercent.toFixed(2)}%)
            </span>
          </div>
        </div>
      </div>
      <div className={`bg-gray-100 p-2 flex justify-between items-center ${isSm ? 'text-[12px] flex-col' : 'text-sm'} text-gray-600 border-t border-gray-300`}>
        <span>
          {currentTime.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: 'America/New_York'
          }).replace(',', '').replace('PM', 'pm').replace('AM', 'am') + ' NY time'}
        </span>
        <a href="https://www.kitco.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
          Powered by Kitco.com
        </a>
      </div>
    </div>
  );
};

export default FinalKitcoPriceCalculator;
