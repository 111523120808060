import React, { useState } from 'react';
import { ReactComponent as Logo } from '../assets/icons/kitco-logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form"
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {JSEncrypt} from 'jsencrypt';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../@/components/ui/select';
import clsx from 'clsx';
import ApiService from 'api/apiService';
import { LucideCircleX } from 'lucide-react';

type Inputs = {
  name: string;
  email: string;
  message: string; 
  category: string; 
  company_name: string; 
  total_user: string; 
  total_views: string; 
}

function ContactUsPage() {
  const navigate = useNavigate();
  const [isSend, setIsSend] = useState(false); 
  const {
    register,
    handleSubmit,
    setValue, 
    trigger, 
    watch, 
    reset,
    formState: { errors },
  } = useForm<Inputs>(); 
  
  const key = process.env.REACT_APP_KEY_ENCRYPT ?? ''; 

  const encryptLong = async (str: string) => {
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(key); 

    const maxChunkLength = 100
    let output = ''
    let inOffset = 0

    while (inOffset < str.length) {
      output += encryptor.encrypt(
        str.substring(inOffset, inOffset + maxChunkLength),
      )
      inOffset += maxChunkLength
    }
    return output
  }

  const handleSendData = async (data: Inputs) => {
    try {
      let message = `Name: ${data.name}, Message: ${data.message}. Email: ${data.email}`; 
      if(data.company_name) {
        message += ` Company name: ${data.company_name}.`; 
      }
      if(data.total_user) {
        message += ` Total Users per Month: ${data?.total_user}.`
      } 
  
      if(data.total_views) {
        message += ` Total Views per Month: ${data.total_views}.`
      }
      console.log('message', message); 
      const messageData = {
        subject: data.category, 
        message: message, 
      }
      // const stringToEncrypt = JSON.stringify(messageData); 
      // const encryptedData = await encryptLong(stringToEncrypt);
      // const sendData = {data: encryptedData}; 

      await ApiService.sendFeedback(messageData); 
      reset();
      setIsSend(true); 
    } catch (error) {
      console.log('Sending feedback error', error); 
    }
    
  }
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleSendData(data); 
    
  }
  const selectedCategory = watch('category'); 

  const categories = [
    { value: 'suggestions', label: 'Suggestions' },
    { value: 'problem_display_data', label: 'Problem with display data' },
    { value: 'other', label: 'Other' },
    { value: 'custom_widget', label: 'Request the custom widget' },
  ];
  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center h-full px-[20px] lg:px-none'>
      <div className='w-full flex justify-between pl-4 pt-2'>
        <Logo
          className='h-[30px] cursor-pointer'
          onClick={() => navigate('/')}
        />
         <div className='pr-2'><LucideCircleX className='cursor-pointer' width={30} height={30} onClick={() => navigate('/widgets')}/></div>
      </div>
      {!isSend && <div className='pt-[40px] w-full lg:w-1/3 h-full flex flex-col justify-center items-center'>
        <div className='text-[30px] font-bold uppercase'>Contact Us</div>
        <div className='w-full h-[1px] bg-[#000] my-2'></div>
        <div className='text-[16px]'>
          Fill out the form below to send us a message. 
        </div>
        <div className='text-[16px]'>
          We will get back to you as soon as possible.
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className='w-full flex justify-center'>
          <div className='w-full'>
            <div className='w-full grid grid-cols-1 gap-4 pt-6'>
              <input {...register("name", { required: true })} placeholder='Name *' className={clsx('h-[45px] outline-none border-b-[1px] border-[#DEDEDE] pl-2 placeholder:text-[14px]', {
                'border-b-red-400 placeholder:text-red-400': errors.name
              })}/>
              <input {...register("email", { required: true })} placeholder='Email *' className={clsx('h-[45px] outline-none border-b-[1px] border-[#DEDEDE] pl-2 placeholder:text-[14px]', {
                'border-b-red-400 placeholder:text-red-400': errors.email
              })}/>
              <div className='w-full'>
                <Select
                  {...register("category", { required: true })}
                  onValueChange={(value) => {
                    console.log('value');
                    setValue('category', value);
                    trigger('category');
                  }}
                >
                  <SelectTrigger className={clsx('w-full h-[45px] border-t-0 border-l-0 border-r-0 border-b-[1px] border-[#DEDEDE] rounded-none outline-none', {
                    'border-b-red-400 placeholder:text-red-400': errors.category
                  })}>
                    <SelectValue placeholder='Category' className='text-[14px] text-[#DEDEDE]'/>
                  </SelectTrigger>
                  <SelectContent>
                    {categories.map((cat) => (
                      <SelectItem
                        key={cat.value}
                        value={cat.value}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {cat.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {selectedCategory === 'custom_widget' && <input {...register("company_name", { required: true })} placeholder='Company name *' className={clsx('h-[45px] outline-none border-b-[1px] border-[#DEDEDE] pl-2 placeholder:text-[14px]', {
                'border-b-red-400 placeholder:text-red-400': errors.company_name
              })}/>}

              {selectedCategory === 'custom_widget' && <input {...register("total_user", { required: true })} placeholder='Total Users per Month *' className={clsx('h-[45px] outline-none border-b-[1px] border-[#DEDEDE] pl-2 placeholder:text-[14px]', {
                'border-b-red-400 placeholder:text-red-400': errors.total_user
              })}/>}

              {selectedCategory === 'custom_widget' && <input {...register("total_views", { required: true })} placeholder='Total Views per Month *' className={clsx('h-[45px] outline-none border-b-[1px] border-[#DEDEDE] pl-2 placeholder:text-[14px]', {
                'border-b-red-400 placeholder:text-red-400': errors.total_views
              })}/>}

              <div className='w-full pt-6'>
                <Textarea {...register("message", { required: true })} placeholder='Message *' rows={5} className={clsx('border-[1px] rounded-none border-[#DEDEDE] placeholder:text-[14px] focus:outline-none', {
                  'border-red-400 placeholder:text-red-400': errors.message
                })}/>
              </div>
            </div>

            <div className='w-full pt-6 flex justify-center'>
              <button type='submit' className='w-full py-2 bg-[#000] rounded-lg text-white text-[20px]'>
                Send
              </button>
            </div>
          </div>
        </form>
      </div>}
      {isSend && <div className='pt-[40px] w-full lg:w-1/3 h-full flex flex-col justify-center items-center'>
        <div className='text-[30px] font-bold uppercase'>Thank You</div>
        <div className='w-full h-[1px] bg-[#000] my-2'></div>
        <div className='text-[12px] w-full text-center'>
           Thank you for taking the time to share your thoughts and insights about our widgets. Your feedback is invaluable in helping us improve our products and services. 
        </div>
        <div className='text-[12px] pt-2'>
          The Kitco team 
        </div>
        <div className='text-[12px] pt-[50px] flex items-center w-full justify-center pr-1'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#EEBC4E" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6"/></svg>
           <Link to="/">Back to Kitco.com</Link>
        </div>
      </div>}
    </div>
  );
}

export default ContactUsPage;
