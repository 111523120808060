import React, { useState, useEffect } from 'react';
import { Switch } from '../@/components/ui/switch';
import ApiService from 'api/apiService';

interface CrossRatesTableProps {
  config: WidgetConfig; 
}

const metals = [
  { symbol: "AU", name: "Gold", color: "#FFD700" },
  { symbol: "AG", name: "Silver", color: "#C0C0C0" },
  { symbol: "PT", name: "Platinum", color: "#E5E4E2" },
  { symbol: "PD", name: "Palladium", color: "#CED0DD" },
];

const currencies = [
  { symbol: "USD", name: "US Dollar", color: "#85bb65" },
  { symbol: "CAD", name: "Canadian Dollar", color: "#FF0000" },
  { symbol: "EUR", name: "Euro", color: "#0052B4" },
  { symbol: "GBP", name: "British Pound", color: "#012169" },
  { symbol: "JPY", name: "Japanese Yen", color: "#BC002D" },
  { symbol: "CHF", name: "Swiss Franc", color: "#FF0000" },
  { symbol: "AUD", name: "Australian Dollar", color: "#00008B" },
  // { symbol: "NZD", name: "New Zealand Dollar", color: "#00247D" },
  { symbol: "CNY", name: "Chinese Yuan", color: "#DE2910" },
  { symbol: "INR", name: "Indian Rupee", color: "#FF9933" },
  { symbol: "MXN", name: "Mexican Peso", color: "#006847" },
  { symbol: "BRL", name: "Brazilian Real", color: "#009c3b" },
  { symbol: "ZAR", name: "South African Rand", color: "#007A4D" },
  { symbol: "RUB", name: "Russian Ruble", color: "#0039A6" },
];

const weightUnits = [
  { value: 'oz', label: 'Troy Ounces', conversionFactor: 1 },
  { value: 'g', label: 'Grams', conversionFactor: 31.1034768 },
  { value: 'kg', label: 'Kilograms', conversionFactor: 0.0311034768 },
  { value: 'tael', label: 'Tael', conversionFactor: 0.8333333 },
  { value: 'tola', label: 'Tola', conversionFactor: 2.6666666 },
];

const generateRandomRate = (base: string, quote: string) => {
  const baseRates: any = {
    XAU: 1800, XAG: 25, XPT: 1000, XPD: 2200,
    USD: 1, CAD: 1.25, EUR: 0.85, GBP: 0.75, JPY: 110, CHF: 0.92,
    AUD: 1.35, NZD: 1.45, CNY: 6.5, INR: 75, MXN: 20, BRL: 5.3,
    ZAR: 15, RUB: 75
  };
  return (baseRates[quote] / baseRates[base] * (0.98 + Math.random() * 0.04)).toFixed(4);
};

const CrossRatesTable = ({config}: CrossRatesTableProps) => {
  const [rates, setRates] = useState<any>({});
  const [previousRates, setPreviousRates] = useState<any>({});
  const [selectedUnit, setSelectedUnit] = useState(weightUnits[0]);
  const [showChange, setShowChange] = useState(false);

  const getData = async () => {
    try {
      setPreviousRates(rates);
      const responsePM = await ApiService.getPMBaseData(); 
      const response = await ApiService.getCurrency(); 
      const ratesList:any = {}; 
      const pmMetalsPrices: any = {}; 
      response.Rates.Rate.forEach(item => ratesList[item.Symbol] = item.CtoUSD); 
      responsePM.PreciousMetals.PM.forEach(item => pmMetalsPrices[item.Symbol] = item.Bid); 
      const newRates:any = {};
      currencies.forEach(currency => {
        newRates[currency.symbol] = {};
        metals.forEach(metal => {
          newRates[currency.symbol][metal.symbol] = (pmMetalsPrices[metal.symbol] * ratesList[currency.symbol])?.toFixed(2);
        });
      });
      setRates(newRates);
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 10000);
    return () => clearInterval(interval);
  }, []);

  const convertRate = (rate: string, unit: { value?: string; label?: string; conversionFactor: any; }) => {
    return (parseFloat(rate) * unit.conversionFactor).toFixed(2);
  };

  const calculateChange = (current: string, previous: string) => {
    if (!previous) return { change: 0, changePercent: 0 };
    const change = parseFloat(current) - parseFloat(previous);
    const changePercent = (change / parseFloat(previous)) * 100;
    return { change: change.toFixed(2), changePercent: changePercent.toFixed(2) };
  };

  return (
    <div className="font-sans" style={{width: config?.width ?? '100%'}}>
      <div className={`border border-gray-200 rounded-lg shadow-md overflow-hidden ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} >
        <div className={`p-4 border-b border-gray-200 flex justify-between items-center ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-50 text-gray-800'}`}>
          <h2 className={`text-xl font-bold ${config.colorTheme === 'light' ?  'text-gray-800' : 'text-gray-100'}`}>Metals and Currencies Cross Rates</h2>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <label htmlFor="show-change" className={`text-sm font-medium ${config.colorTheme === 'light' ?  'text-gray-700' : 'text-gray-100'}`}>Show Change</label>
               <Switch id="show-change" checked={showChange} onCheckedChange={setShowChange} /> 
            </div>
            <div className="flex items-center">
              <label htmlFor="unit-select" className="mr-2 text-sm font-medium text-gray-700">Unit:</label>
              <select
                id="unit-select"
                value={selectedUnit.value}
                onChange={(e) => {
                  const result = weightUnits.find(unit => unit.value === e.target.value); 
                  if(result) {
                    setSelectedUnit(result); 
                  }
                } }
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md text-gray-800"
              >
                {weightUnits.map((unit) => (
                  <option key={unit.value} value={unit.value}>{unit.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className={`${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-100 text-gray-800'}`}>
                <th className="p-2 border-b border-r border-gray-200"></th>
                {metals.map(metal => (
                  <th key={metal.symbol} className="p-2 border-b border-r border-gray-200 font-bold text-sm">
                    <div className="flex items-center justify-center">
                      <div className="w-4 h-4 rounded-full mr-1" style={{ backgroundColor: metal.color }}></div>
                      {metal.name} (per {selectedUnit.label})
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currencies.map(currency => (
                <tr key={currency.symbol}>
                  <th className={`p-2 border-b border-r border-gray-200 font-bold text-sm ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-50 text-gray-800'}`}>
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full mr-1" style={{ backgroundColor: currency.color }}></div>
                      {currency.name}
                    </div>
                  </th>
                  {metals.map(metal => {
                    const rate = rates[currency.symbol]?.[metal.symbol];
                    const previousRate = previousRates[currency.symbol]?.[metal.symbol];
                    const { change, changePercent } = calculateChange(rate, previousRate);
                    const convertedRate = rate ? convertRate(rate, selectedUnit) : '-';
                    const changeClass = parseFloat(`${change}`) >= 0 ? 'text-green-600' : 'text-red-600';
                    
                    return (
                      <td key={`${currency.symbol}-${metal.symbol}`} className="p-2 border-b border-r border-gray-200 text-center">
                        <div>{convertedRate}</div>
                        {showChange && (
                          <div className={`text-xs ${changeClass}`}>
                            {change} ({changePercent}%)
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={`flex justify-end items-center py-2 px-4 border-t border-gray-200 text-sm font-bold mt-2 rounded-b-lg bg-gray-100`}>
        <a href="https://www.kitco.com/" target="_blank" rel="noopener noreferrer" className="flex items-center text-black no-underline">
          <div className="flex items-center mr-2">
            <span className="bg-yellow-400 text-black px-1 rounded mr-1 text-xs font-bold">K</span>
            <span className="text-sm font-bold">KITCO</span>
          </div>
          Powered by Kitco.com
        </a>
      </div>
    </div>
  );
};

export default CrossRatesTable;
