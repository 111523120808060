import MainMenu from 'components/Sidebar/MainMenu';
import { Menu } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Logo } from '../assets/icons/kitco-logo.svg'; 
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationSection from 'components/NavigationSection/NavigationSection';

interface AppLayoutProps {
  children: JSX.Element;
}

function AppLayout({ children }: AppLayoutProps) {
  const [isOpen, setIsOpen] = useState(false); 
  const navigate = useNavigate(); 
  const refTop = useRef<any>(null);
  const {pathname} = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    refTop?.current?.scrollIntoView(); 
  }, [pathname]);  

  return (
    <div className='w-full bg-gray-50' ref={refTop} >
      <div className={`fixed w-full h-[65px] border-b border-b-1 border-gray-300 z-50 inset-0 bg-white/30 lg:backdrop-blur-md ${isOpen ? 'blur-[4px] border-none' : 'backdrop-blur-md'}`}>
        <div className='flex w-full items-center pl-4 h-full font-semibold text-[18px]'>
            <Logo className='mr-2 h-[25px] cursor-pointer' onClick={() => navigate('/')}/>  
            <span className='hidden lg:inline-block h-[25px]'>
              Widgets
            </span>   
            <Menu className='lg:hidden' onClick={toggleMenu} size={24}/>
        </div>
      </div>
      <div className={`w-full relative flex  {!isOpen ? '' : ''}`}>
        <MainMenu isOpen={isOpen} handleClose={() => setIsOpen(false)}/>   
        
        <div className={`lg:pl-[250px] w-full pt-[45px] px-[12px] lg:px-0 relative ${isOpen ? 'blur-[4px]' : ''}`}>
          {pathname !== '/widgets' && <NavigationSection /> } 
          <div className='pt-[20px]'></div>
          {children}
          </div>
      </div>
    </div>
  );
}

export default AppLayout;
