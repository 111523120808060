import React, { useState, useEffect, useRef } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, PieProps } from 'recharts'
import RadianTooltip from './RadianTooltip'
import { DataObject, Instrument } from './types'
import { ReactComponent as Logo } from '../assets/icons/kitco-logo.svg'; 

interface PieChartVisualizationProps {
  data: DataObject
  selectedInstrument: Instrument
  isDarkMode: boolean
  labelPosition?: number
  labelOffset?: number
}

const PieChartVisualization: React.FC<PieChartVisualizationProps> = ({
  data,
  isDarkMode,
}) => {
  const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 })
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        setChartDimensions({
          width: chartRef.current.clientWidth,
          height: chartRef.current.clientHeight
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  // Calculate percentages based on total change
  const usdChangePercent = (data.usdChange / data.totalChange) * data.totalChangePercent
  const marketChangePercent = (data.marketChange / data.totalChange) * data.totalChangePercent

  const pieData = [
    { 
      name: data.usdChange >= 0 ? 'USD Strength' : 'USD Weakness', 
      value: Math.abs(data.usdChange), 
      percent: usdChangePercent, 
      isPositive: data.usdChange >= 0 
    },
    { 
      name: data.marketChange >= 0 ? 'Buyer-Driven Change' : 'Seller-Driven Change', 
      value: Math.abs(data.marketChange), 
      percent: marketChangePercent, 
      isPositive: data.marketChange >= 0 
    },
  ]


  const getColor = (change: number) => change >= 0 ? '#2e8b57' : '#ef4444' 
  const usdColor = getColor(data.usdChange)
  const instrumentColor = getColor(data.marketChange)
  const colors = [usdColor, instrumentColor]

  const renderActiveShape: PieProps['activeShape'] = (props: any) => {
    return <RadianTooltip {...props} isDarkMode={isDarkMode} chartWidth={chartDimensions.width} chartHeight={chartDimensions.height} symbol={data.symbol}/>
  }

  // Calculate chart size based on screen width
  const chartSize = chartDimensions.width < 400 ? '100%' : '100%'

  return (
    <div ref={chartRef} className="w-full h-96 md:h-[280px] flex justify-center items-center overflow-auto">
      <div className="relative w-full h-full flex justify-center items-center" style={{ minWidth: '303px', minHeight: '269px' }}>
        <ResponsiveContainer width={chartSize} height={chartSize}>
          <PieChart>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              innerRadius={chartDimensions.width < 400 ? "30%" : "40%"}
              outerRadius={chartDimensions.width < 400 ? "50%" : "60%"}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              activeShape={renderActiveShape}
              activeIndex={[0, 1]}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className='absolute z-20  bottom-0 right-2'>
          <Logo className='h-[20px] w-auto'/>
        </div>
      </div>
    </div>
  )
}

export default PieChartVisualization
