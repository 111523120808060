interface SettingsProps {
  isContainerChecked: boolean;
  isTransparentChecked: boolean;
  widthValue: string;
  theme: 'light' | 'dark';
  defaultWidth: string;
  handleChange: (e: any) => void;
  handleContainerChecked: (e: any) => void;
  handleThemeChange: (e: any) => void;
  handleTrasparentChecked: (e: any) => void;
}

function SettingsWidget({
  isContainerChecked,
  isTransparentChecked,
  widthValue,
  theme,
  handleChange,
  handleContainerChecked,
  handleThemeChange,
  handleTrasparentChecked,
  defaultWidth,
}: SettingsProps) {
  return (
    <div className='pt-[40px] w-full space-y-2'>
      <div className='text-[20px] font-semibold '>Settings</div>
      <div className='flex items-center w-full pt-2'>
        <div className='w-1/4 lg:w-1/3 text-[14px]'>Width</div>
        <div className='flex space-x-4'>
          <input
            disabled={isContainerChecked}
            type='text'
            value={widthValue}
            placeholder={defaultWidth}
            onChange={handleChange}
            className='border outline-none px-2 text-[14px] h-[30px] w-[100px]'
          />
          <div className='flex items-center'>
            <label className='cursor-pointer flex items-center text-[14px]'>
              <input
                type='checkbox'
                checked={isContainerChecked}
                onChange={handleContainerChecked}
                className='mr-1 h-[15px] w-[15px]'
              />
              Use container size
            </label>
          </div>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='w-1/4 lg:w-1/3 text-[14px]'>Theme</div>
        <div className='flex items-center space-x-2'>
          <select
            id='theme-select'
            value={theme}
            onChange={handleThemeChange}
            className={`
          text-gray-700 px-4 text-[14px] h-[35px] border outline-none transition-colors duration-300
          dark:bg-gray-700 dark:text-gray-300 focus:outline-none grow w-[200px] lg:w-[300px]
        `}
          >
            <option value='light'>Light</option>
            <option value='dark'>Dark</option>
          </select>
        </div>
      </div>
      <div className='flex items-center'>
        <label className='w-full flex items-center'>
          <div className='w-1/2 lg:w-1/3 text-[14px]'>Transparent background</div>
          <input
            type='checkbox'
            checked={isTransparentChecked}
            onChange={handleTrasparentChecked}
            className='mr-1 h-[15px] w-[15px] cursor-pointer'
          />
        </label>
      </div>
    </div>
  );
}

export default SettingsWidget;
