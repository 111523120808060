interface PropsI {
    index: number; 
    text: string; 
}
 
const TutorialTextItem = ({index, text}: PropsI) => {
    return (
        <div className='flex space-x-2'>
        <div className='w-[30px] h-[30px] rounded-full bg-[#000] text-white font-medium flex justify-center items-center pb-[1px]'>{index}</div>
        <div>{text}</div>
      </div>
    )
}

export default TutorialTextItem; 