import { bucketUrl } from "./constants";

const widgetUrl = process.env.REACT_APP_ENV === 'staging' ? bucketUrl.DEV : bucketUrl.PROD; 
export const WidgetsConfigList: WidgetConfigListI = {
    goldBid: {
        container_id: 'widget_gold_bid',
        script_link_display: `${widgetUrl}/widgetGoldBid.bundle.js`,
        script_link: `${widgetUrl}/widgetGoldBid.bundle.js`,
        method_name: 'createGoldBidWidget'
    },
    compactPrice: {
        container_id: "widget_compact_price",
        script_link_display: `${widgetUrl}/widgetCompactPrice.bundle.js`,
        script_link: `${widgetUrl}/widgetCompactPrice.bundle.js`,
        method_name: "createCompactPriceWidget"
    },
    spotPrice: {
        container_id: "widget_spot_price",
        script_link_display: `${widgetUrl}/widgetSpotPrice.bundle.js`,
        script_link: `${widgetUrl}/widgetSpotPrice.bundle.js`,
        method_name: "createSpotePriceWidget"
    },
    londonFix: {
        container_id: "widget_london_fix",
        script_link_display: `${widgetUrl}/widgetLondonFix.bundle.js`,
        script_link: `${widgetUrl}/widgetLondonFix.bundle.js`,
        method_name: "createLondonFixWidget"
    },
    news: {
        container_id: "widget_news",
        script_link_display: `${widgetUrl}/widgetNews.bundle.js`,
        script_link: `${widgetUrl}/widgetNews.bundle.js`,
        method_name: "createNewsWidget"
    },
    calculator: {
        container_id: "widget_calculator",
        script_link_display: `${widgetUrl}/widgetCalculator.bundle.js`,
        script_link: `${widgetUrl}/widgetCalculator.bundle.js`,
        method_name: "createCalculatorWidget"
    },
    ticker: {
        container_id: "widget_ticker",
        script_link_display: `${widgetUrl}/widgetTicker.bundle.js`,
        script_link: `${widgetUrl}/widgetTicker.bundle.js`,
        method_name: "createTickerWidget",
        width: "100%"
    },
    crossRates: {
        container_id: "widget_cross_rates",
        script_link_display: `${widgetUrl}/widgetCrossRates.bundle.js`,
        script_link: `${widgetUrl}/widgetCrossRates.bundle.js`,
        method_name: "createCrossRatesWidget",
        width: "100%"
    }
}