import React from 'react'

interface InfoPopupProps {
  isOpen: boolean
  onClose: () => void
}

const InfoPopup: React.FC<InfoPopupProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white p-6 rounded-lg max-w-md">
        <h3 className="text-lg font-bold mb-2">How to Read This Widget</h3>
        <p className="mb-2">This visualization shows the influence of USD and instrument trade factors on the price:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Bubbles represent USD and instrument trade influences.</li>
          <li>Green indicates positive change, red indicates negative, and grey indicates no change.</li>
          <li>Size reflects the magnitude of change.</li>
          <li>The values show the change amount and percentage for each factor.</li>
        </ul>
        <button
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default InfoPopup
