import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const metals = [
  { name: 'Gold', symbol: 'XAU', color: '#FF0000' },
  { name: 'Silver', symbol: 'XAG', color: '#C0C0C0' },
  { name: 'Platinum', symbol: 'XPT', color: '#E5E4E2' },
  { name: 'Palladium', symbol: 'XPD', color: '#CED0DD' }
];

const timeframes = ['1D', '1M', '3M', '1Y', '5Y', 'All'];

const PreciousMetalsWidget = () => {
  const [selectedMetal, setSelectedMetal] = useState('XAU');
  const [selectedTimeframe, setSelectedTimeframe] = useState('1D');
  const [priceData, setPriceData] = useState<any>({});
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    // Simulating API call to fetch data
    const fetchData = async () => {
      const mockData = {
        XAU: { price: 2459.30, change: -4.68, changePercent: -0.19 },
        XAG: { price: 23.18, change: 0.22, changePercent: 0.96 },
        XPT: { price: 929.00, change: 7.00, changePercent: 0.76 },
        XPD: { price: 1252.00, change: -15.00, changePercent: -1.18 }
      };
      setPriceData(mockData);

      // Generate mock chart data for gold (3 days, hourly intervals)
      const basePrice = 2459;
      const volatility = 8;
      const dataPoints = 3 * 24; // 3 days * 24 hours
      const mockChartData = Array.from({ length: dataPoints }, (_, i) => {
        const time = i;
        const day = Math.floor(time / 24) + 1;
        const hourOfDay = time % 24;
        const randomChange = (Math.random() - 0.5) * volatility;
        return {
          time: time,
          price: basePrice + randomChange + Math.sin(time / 8) * 5,
          day: day
        };
      });
      setChartData(mockChartData);
    };

    fetchData();
  }, [selectedMetal, selectedTimeframe]);

  const formatXAxis = (value: number) => {
    const day = Math.floor(value / 24) + 1;
    const hour = value % 24;
    return `${day}d ${hour.toString().padStart(2, '0')}:00`;
  };

  const renderChart = (metal: any) => {
    if (metal.symbol === 'XAU') {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="time" 
              tickFormatter={formatXAxis} 
              ticks={[0, 24, 48, 71]} 
              domain={[0, 71]}
            />
            <YAxis domain={['auto', 'auto']} />
            <Line 
              type="monotone" 
              dataKey="price" 
              stroke={metal.color} 
              dot={false} 
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      // Simplified chart for other metals
      return (
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={chartData}>
            <Line type="monotone" dataKey="price" stroke={metal.color} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardContent className="p-4">
        <Tabs value={selectedMetal} onValueChange={setSelectedMetal}>
          <TabsList className="grid grid-cols-4 gap-2 mb-4">
            {metals.map(metal => (
              <TabsTrigger key={metal.symbol} value={metal.symbol} className="text-xs">
                {metal.name}
              </TabsTrigger>
            ))}
          </TabsList>
          {metals.map(metal => (
            <TabsContent key={metal.symbol} value={metal.symbol}>
              <div className="mt-4">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold">{metal.name} Price</h2>
                  <div className="text-right">
                    <p className="text-xl font-bold">
                      USD{priceData[metal.symbol]?.price.toFixed(2)}
                    </p>
                    <p className={`text-sm ${priceData[metal.symbol]?.change > 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {priceData[metal.symbol]?.change.toFixed(2)} ({priceData[metal.symbol]?.changePercent.toFixed(2)}%)
                    </p>
                  </div>
                </div>
                {renderChart(metal)}
                <div className="mt-4 flex justify-between">
                  {timeframes.map(timeframe => (
                    <button
                      key={timeframe}
                      onClick={() => setSelectedTimeframe(timeframe)}
                      className={`px-2 py-1 text-xs rounded ${selectedTimeframe === timeframe ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                      {timeframe}
                    </button>
                  ))}
                </div>
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </CardContent>
      <CardFooter className="pt-2 pb-4">
        <p className="text-xs text-gray-500 w-full text-right">Powered by Kitco.com</p>
      </CardFooter>
    </Card>
  );
};

export default PreciousMetalsWidget;
