import React from 'react'
import { DollarSign } from 'lucide-react'
import Bubble from './Bubble'
import { DataObject, Instrument } from './types'

interface BubbleVisualizationProps {
  data: DataObject
  selectedInstrument: Instrument
  isDarkMode: boolean
}

const BubbleVisualization: React.FC<BubbleVisualizationProps> = ({ data, selectedInstrument, isDarkMode }) => {
  const maxChange = Math.max(Math.abs(data.usdChange || 0), Math.abs(data.marketChange || 0))
  const getSize = (value: number): number => Math.max(100, Math.min(150, (Math.abs(value) / (maxChange || 1)) * 150))

  const usdSize = getSize(data.usdChange)
  const instrumentSize = getSize(data.marketChange)

  return (
    <div className={`relative w-full h-64 rounded-lg overflow-hidden ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
      <Bubble
        label="USD"
        change={data.usdChange}
        changePercent={data.usdChangePercent}
        icon={DollarSign}
        symbol={data.symbol}
        size={usdSize}
        isBig={usdSize > instrumentSize}
        x={30}
        y={50}
      />
      <Bubble
        label={`${selectedInstrument.name} Trade`}
        change={data.marketChange}
        changePercent={data.marketChangePercent}
        icon={selectedInstrument.icon}
        size={instrumentSize}
        symbol={data.symbol}
        isBig={instrumentSize > usdSize}
        x={70}
        y={50}
      />
      <div className="absolute bottom-2 right-2 text-sm font-semibold text-gray-400">
        www.kitco.com
      </div>
    </div>
  )
}

export default BubbleVisualization
