import { WidgetsConfigList } from "common/widgetsConfig";
import EmbedCode from "components/EmbedCode/EmbedCode";
import ButtonsSection from "components/SettingsWidget/ButtonsSection";
import SettingsWidget from "components/SettingsWidget/SettingsWidget";
import { useSettingsWidgetHandler } from "hooks/useSettingsWidgetHandler";
import LondonFixWidget from "widgets/LondonFixWidget";

function LondonFixWidgetPage() {

  const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
    handleApply,
    config
  } = useSettingsWidgetHandler({ widthDefault: '700' });

  const embedCodeConfig = WidgetsConfigList.londonFix; 

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>London Fix Widget </div>
      <div className='w-full lg:w-2/3 flex flex-col items-center'>
    
        <LondonFixWidget config={config}/>

        <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='700'
          handleTrasparentChecked={handleTrasparentChecked}
        />

        <ButtonsSection
          isTransparentChecked={isTransparentChecked}
          widthValue={widthValue}
          theme={theme}
          handleApply={handleApply}
          widgetType='comodity_widget'
          embedCodeConfig={embedCodeConfig}
          handleResetSettings={handleResetSettings}
        />

        <EmbedCode
          width={widthValue}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          widgetType='comodity_widget'
          embedCodeConfig={embedCodeConfig}
        />

      </div>
    </div>
  );
}

export default LondonFixWidgetPage;