import { categoryBySymbol } from 'common/constants'
import { DataObject, Instrument } from './types'

const API_ENDPOINTS = {
     metals: 'https://proxy.kitco.com/getPM?symbol=AU,AG,PT,PD&kgx=yes&type=json',
    oil: 'https://proxy.kitco.com/getValue?ver=2.0&symbol=CL&df=2&tf=2&type=json&kgx=yes',
    baseMetals: 'https://proxy.kitco.com/getBM?symbol=CU,NI,AL,ZN,PB,UR&kgx=yes&type=json', 
/*     metals: 'https://proxy.kitco.com/getPM?symbol=au,ag,pt,pd',
    oil: 'https://proxy.kitco.com/getValue?symbol=CL',
    baseMetals: 'https://proxy.kitco.com/getBM?symbol=CU,NI,AL,ZN,PB,UR' */
    
}

interface MetalApiResponse {
    PreciousMetals?: {
        PM: Array<MetalData>
    }

    BaseMetals?: {
        PM: Array<MetalData>
    }
}

interface MetalData {
    Symbol: string;
    Mid: number;
    Change: number;
    ChangePercentage: number;
    ChangeUSD: number;
    ChangePercentUSD: number;
    Timestamp: string;
    ChangeTrade: number; 
}

interface OilApiResponse {
    Values: {
        Value: {
            ChangePercentage: number
            ChangePercentTrade: number
            ChangeTrade: number
            Price: number
            Symbol: string
            ChangeUSD: number
            Change: number
            ChangePercentUSD: number
            Timestamp: string
        }
    }
}

export const fetchDataFromAPI = async (instrument: Instrument): Promise<DataObject> => {
    try {
        let endpoint: string
        switch (instrument.apiType) {
            case 'precious':
                endpoint = API_ENDPOINTS.metals
                break
            case 'base':
                endpoint = API_ENDPOINTS.baseMetals
                break
            case 'oil':
                endpoint = API_ENDPOINTS.oil
                break
            default:
                throw new Error('Unknown instrument type')
        }

        const response = await fetch(endpoint)

        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        const data = await response.json()

        if (instrument.apiType === 'precious' || instrument.apiType === 'base') {
            return transformMetalData(data as MetalApiResponse, instrument)
        } else if (instrument.apiType === 'oil') {
            return transformOilData(data as OilApiResponse)
        } else {
            throw new Error('Unknown instrument')
        }
    } catch (error) {
        console.error("Failed to fetch data from API:", error)
        return generateRandomData()
    }
}

const transformMetalData = (data: MetalApiResponse, instrument: Instrument): DataObject => {
    const symbolMap = {
        'gold': 'AU',
        'silver': 'AG',
        'platinum': 'PT',
        'palladium': 'PD',
        'copper': 'CU',
        'nickel': 'NI',
        'aluminum': 'AL',
        'zinc': 'ZN',
        'lead': 'PB'
    }

    const metalArray = instrument.apiType === 'precious' ? data.PreciousMetals?.PM : data.BaseMetals?.PM;
    if (!metalArray) {
        throw new Error('Metal data not found in API response');
    }

    const metalData = metalArray.find(metal => metal.Symbol === symbolMap[instrument.id as keyof typeof symbolMap])

    if (!metalData) {
        throw new Error('Specific metal data not found in API response')
    }

    return {
        price: metalData.Mid,
        previousPrice: metalData.Mid - metalData.Change,
        usdChange: metalData.ChangeUSD,
        usdChangePercent: metalData.ChangePercentUSD,
        marketChange: metalData.Change - metalData.ChangeUSD,
        marketChangePercent: metalData.ChangePercentage - metalData.ChangePercentUSD,
        totalChange: metalData.Change,
        totalChangePercent: metalData.ChangePercentage,
        changeTrade: metalData?.ChangeTrade, 
        timestamp: new Date(metalData.Timestamp).toLocaleTimeString(),
        symbol: metalData?.Symbol
    }
}

const transformOilData = (data: OilApiResponse): DataObject => {
    const oilData = data.Values.Value;

    return {
        price: oilData.Price,
        previousPrice: oilData.Price - oilData.Change,
        usdChange: oilData.ChangeUSD,
        usdChangePercent: oilData.ChangePercentUSD,
        marketChange: oilData.Change - oilData.ChangeUSD,
        marketChangePercent: oilData.ChangePercentage - oilData.ChangePercentUSD,
        totalChange: oilData.Change,
        totalChangePercent: oilData.ChangePercentage,
        changeTrade: oilData?.ChangeTrade, 
        symbol: oilData?.Symbol, 
        timestamp: new Date(oilData.Timestamp).toLocaleString(),
    }
}

export const generateRandomData = (): DataObject => {
    const basePrice = 2000
    const totalChangePercent = (Math.random() - 0.5) * 0.04 // -2% to 2% change
    const totalChange = basePrice * totalChangePercent
    const usdChangePercent = (Math.random() - 0.5) * 0.03 // -1.5% to 1.5% change
    const usdChange = basePrice * usdChangePercent
    const marketChange = totalChange - usdChange

    return {
        price: Number((basePrice + totalChange).toFixed(2)),
        previousPrice: basePrice,
        usdChange: Number(usdChange.toFixed(2)),
        usdChangePercent: Number((usdChangePercent * 100).toFixed(2)),
        marketChange: Number(marketChange.toFixed(2)),
        marketChangePercent: Number(((marketChange / basePrice) * 100).toFixed(2)),
        totalChange: Number(totalChange.toFixed(2)),
        totalChangePercent: Number((totalChangePercent * 100).toFixed(2)),
        timestamp: new Date().toLocaleTimeString(),
        symbol: '',
    }
}

export const getAnalysis = (data: DataObject): string => {
    const realChange = data.marketChange
    const reallyChanged = Math.abs(data.marketChange) >= Math.abs(data.usdChange)
    const yesNo = reallyChanged ? 'Yes' : 'No'

    if (data.totalChange === 0) {
        return `<strong>${yesNo}.</strong> The changes in USD and market factors perfectly cancelled each other out.`
    } else if (Math.abs(data.marketChange) === Math.abs(data.usdChange)) {
        return `<strong>${yesNo}.</strong> Both USD and market factors contributed equally to the change.`
    } else if (reallyChanged) {
        return `<strong>${yesNo}.</strong> In real terms it ${realChange >= 0 ? 'rose' : 'fell'} <strong>$${Math.abs(realChange).toFixed(2)}</strong>, ${data.usdChange !== 0 ? `partially ${data.usdChange > 0 ? 'offset' : 'amplified'} by a <strong>${data.usdChange > 0 ? 'weaker' : 'stronger'}</strong> US Dollar.` : 'with no significant impact from USD changes.'}`
    } else {
        return `<strong>${yesNo}.</strong> In real terms it ${realChange >= 0 ? 'rose' : 'fell'} <strong>$${Math.abs(realChange).toFixed(2)}</strong>, but this change was ${Math.abs(realChange) < Math.abs(data.usdChange) ? 'overshadowed' : 'partially offset'} by a <strong>${data.usdChange > 0 ? 'weaker' : 'stronger'}</strong> US Dollar.`
    }
}

export const splitText = (text: string): { firstHalf: string; secondHalf: string } => {
    const words = text.split(' ')
    if (words.length === 1) return { firstHalf: text, secondHalf: '' }
    const midpoint = Math.ceil(words.length / 2)
    return {
        firstHalf: words.slice(0, midpoint).join(' '),
        secondHalf: words.slice(midpoint).join(' ')
    }
}

export function transformDateTime(inputDateTime: string) {
    const date = new Date(inputDateTime);
    
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const formattedDate = `${formattedHours}${minutes === 0 ? '' : ':' + minutes.toString().padStart(2, '0')} ${ampm} ${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    
    return formattedDate;
  }

  export function getThreeDates(): string[] {
    const result: string[] = [];
    const today = new Date();
  
    // Helper function to format date as 'YYYY-MM-DD'
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-based
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    // Helper function to check if a day is a weekend (Saturday: 6, Sunday: 0)
    const isWeekend = (date: Date): boolean => {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6;
    };
  
    let currentDate = new Date(today); // Start with today
  
    while (result.length < 3) {
      currentDate.setDate(currentDate.getDate() - 1); // Go back one day
      if (!isWeekend(currentDate)) {
        result.push(formatDate(currentDate));
      }
    }
  
    return result;
  }

  export const getNewYorkFormattedDate = (date: Date): string => {
    // Get the UTC offset for New York, considering Daylight Saving Time
    const newYorkOffset = -4; // New York is UTC-4 during Daylight Saving (EDT)
    const standardOffset = -5; // New York is UTC-5 during Standard Time (EST)
  
    // Check if the date falls under Daylight Saving Time
    const isDST = (d: Date): boolean => {
      const january = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      const july = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      return d.getTimezoneOffset() < Math.max(january, july);
    };
  
    // Adjust the date according to the New York offset
    const offset = isDST(date) ? newYorkOffset : standardOffset;
  
    const nyDate = new Date(date.getTime() + offset * 60 * 60 * 1000);
  
    // Format the date in 'YYYY-MM-DD' format
    const year = nyDate.getUTCFullYear();
    const month = String(nyDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(nyDate.getUTCDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  export const getLimit = (symbol: string): number => {
    return categoryBySymbol[symbol] === 'Base Metals' ? 4 : 2; 
 }
  
  
  
  
  
  
