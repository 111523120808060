import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface SubNavigationProps {
  activeOption: SubOption;
  pathname: string; 
}

function SubNavigation({ activeOption, pathname }: SubNavigationProps) {
  const navigate = useNavigate(); 
  const isLongName = activeOption.title === 'Historical Data' || activeOption.title === 'Tickers'; 
  const handleClick = (path: string) => {
    navigate(path); 
  }
  return (
    <div className='flex mt-[20px] border border-[#E2E8F0] rounded-[26px] w-full md:w-max'>
      {activeOption?.subOptions.map((item, idx) => (
        <div onClick={() => handleClick(item.path)} className={clsx('py-3 text-[10px] md:text-[12px] text-[#171717] cursor-pointer text-center', {
          'border-r border-[#E2E8F0]': activeOption?.subOptions.length - 1 !== idx, 
          'mulish-700': pathname === `/widgets/${item.path}`,
          'mulish-400': pathname !== `/widgets/${item.path}`, 
          'w-max grow md:w-[120px]': !isLongName, 
          'w-max grow md:w-[200px]': isLongName, 
        })}>{item.title}</div>
      ))}
    </div>
  );
}

export default SubNavigation;
