import MiniKgxBidWidget from "widgets/allmetals-mini-kgx-bid-widget";

function MiniKgxBidWidgetPage() {

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>Mini KGX Bid Widget</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
    
      <MiniKgxBidWidget/>

      </div>
    </div>
  );
}

export default MiniKgxBidWidgetPage;