import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent, CardHeader } from '../@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../@/components/ui/select';
import { Skeleton } from "../@/components/ui/skeleton";
import { Button } from '../@/components/ui/button';
import { RefreshCw } from 'lucide-react';
import  newsLogo  from '../assets/icons/News_Black_Blue_H.svg'; 
import ApiService from 'api/apiService';

interface NewsWidgetProps {
  config: WidgetConfig; 
}

const NewsWidget = ({config}: NewsWidgetProps) => {
  const [news, setNews] = useState<any[]>([]);
  const [category, setCategory] = useState('all');
  const [isLoading, setIsLoading] = useState(false); 
  const [lastId, setLastId] = useState<number | null>(null); 

  const categories = [
    { value: 'all', label: 'All Stories' },
    { value: 'metals', label: 'Metals' }, 
    { value: 'mining', label: 'Mining' },
    { value: 'crypto', label: 'Crypto' },
    { value: 'economy', label: 'Economy' },
    { value: 'gold', label: 'Gold' }
  ];

  const getData = async (lastId: number = 0, category: string = '') => {
    try {
      setIsLoading(true); 
      const response = await ApiService.getNews(lastId, category); 
      const listNews = response.NewsList.News.map((item, idx) => ({
        id: item.Id,
        title: item.Title,
        time: item.Timestamp,
        image: item.Thumbnail,
        category: item.Category,
        url: item.URL
      })); 
      if(lastId > 0) {
        listNews.shift(); 
      }
      if(lastId === 0) {
        setNews(listNews)
      } else {
        setNews( prev => [...prev, ...listNews]); 
      }
      
    } catch (error) {
      console.log('error', error); 
    } finally {
      setIsLoading(false); 
    }
  }

  const handleRefresh = () => {
    setLastId(null)
    setNews([]); 
    getData(0);
  };

  const observer: any = useRef()
  const lastElementRef = useCallback((node: any) => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setLastId(Number(entries[0].target.id));
      } 
    })
    if (node) {
      observer.current.observe(node); 
    } 
  }, []); 

  useEffect(() => {
    if(lastId) {
      getData(lastId, category); 
    } else {
      getData(0, category);
    }
  }, [lastId, category]); 

  return (
    <Card className={` ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{width: config?.width ?? '100%'}}>
      <CardHeader className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Top Stories</h2>
          <img src={newsLogo} alt="Kitco News Logo" className="h-[20px]" />
        </div>
        <div className="flex justify-between items-center text-gray-800">
          <Select value={category} onValueChange={(value) => {
              setNews([]); 
              setLastId(null); 
              setCategory(value);
            }}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select category" />
            </SelectTrigger>
            <SelectContent>
              {categories.map((cat) => (
                <SelectItem key={cat.value} value={cat.value} onClick={(e) => {
                  e.stopPropagation()
                }}>
                  {cat.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button 
            onClick={handleRefresh} 
            disabled={isLoading}
            size="icon"
            variant="ghost"
            className="text-blue-500 hover:text-blue-700 hover:bg-blue-100"
          >
            <RefreshCw className={`h-5 w-5 ${isLoading ? 'animate-spin' : ''}`} />
            <span className="sr-only">Refresh</span>
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-gray-600 mb-4">
          Help your audience keep track of what's happening in the
          precious metals, mining, crypto, and economic markets with our daily news briefs - designed
          to be read in 20 seconds or less.
        </p>
        <div className="space-y-4 max-h-[400px] overflow-y-auto">
          {news.map((item, idx) => (
            <a 
              ref={news.length - 1 > idx ? lastElementRef : null }
              key={item.id} 
              id={item.id}
              href={item.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-start space-x-3 hover:bg-gray-100 p-2 rounded transition duration-150 ease-in-out"
            >
              <img src={item.image} alt="" className="w-8 h-8 rounded" loading="lazy"/>
              <div>
                <p className="text-xs text-gray-500">{item.time}</p>
                <h3 className="text-sm font-semibold">{item.title}</h3>
              </div>
            </a>
          ))}
          {isLoading && <div className="flex items-center space-x-4">
            <Skeleton className="h-12 w-12 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div> }
        </div>
        <div className="text-right mt-4">
          <a 
            href="https://www.kitco.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline text-sm inline-block"
          >
            Track all markets on Kitco.com
          </a>
        </div>
      </CardContent>
    </Card>
  );
};

export default NewsWidget;
