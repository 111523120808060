import ApiService from 'api/apiService';
import { transformDateTime } from '../components/utils';
import React, { useEffect, useState } from 'react';

interface GoldBidWidgetProps {
  config: WidgetConfig; 
}

const GoldBidWidget = ({config}: GoldBidWidgetProps) => {
  const [showPercentage, setShowPercentage] = useState(false);
  const [bidData, setBidData] = useState<any>({}); 

  const getData = async () => {
    try {
      const response = await ApiService.getCertainPM('AU'); 
      const data = response.PreciousMetals.PM; 
      const bidDataResult = {
        price: data.Bid,
        date: transformDateTime(data.Timestamp),
        time: "11am EST",
        changes: [
          { reason: "USD Weakness", value: data.ChangeUSD, percentage: data.ChangePercentUSD },
          { reason: "Majority Sellers", value: data.ChangeTrade, percentage: data.ChangePercentTrade }
        ],
        totalChange: { value: data.Change, percentage: data.ChangePercentage}
      }
      setBidData(bidDataResult); 
      console.log('response', response)
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  const formatChange = (change: any) => {
    const value = showPercentage ? change.percentage : change.value;
    const prefix = value >= 0 ? '+' : '';
    return `${prefix}${value?.toFixed(2)}${showPercentage ? '%' : ''}`;
  };

  return (
    <div className={`w-full max-w-md shadow-lg rounded-lg overflow-hidden ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{ 
      fontFamily: 'Arial, sans-serif', 
      width: config.width ?? '', 
      border: '1px solid #ccc', 
      borderRadius: '4px', 
      padding: '8px',
      fontSize: '12px'
    }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        marginBottom: '4px'
      }}>
        <span style={{ fontWeight: 'bold', fontSize: '16px', marginRight: '8px' }}>Gold Bid</span>
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{bidData?.price?.toFixed(2)}</span>
      </div>
      <div style={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4px',
        fontSize: '10px',
        color: '#666'
      }}>
        <div style={{ flex: 1 }}></div>
        <span style={{ flex: 2, textAlign: 'center' }}>{bidData?.date}</span>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <button 
            onClick={() => setShowPercentage(!showPercentage)}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: 'red',
              fontSize: '10px',
              padding: '0'
            }}
          >
            {showPercentage ? '$' : '%'}
          </button>
        </div>
      </div>
      {bidData && bidData?.changes?.map((change: any, index: number) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
          <span>Chg due to {change.reason}</span>
          <span style={{ color: change.value >= 0 ? 'green' : 'red' }}>
            {change ? formatChange(change) : null}
          </span>
        </div>
      ))}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        borderTop: '1px solid #ccc', 
        marginTop: '4px', 
        paddingTop: '4px',
        fontWeight: 'bold',
        fontSize: '13px'
      }}>
        <span>Total Gold Change:</span>
        <span style={{ color: bidData?.totalChange?.value >= 0 ? 'green' : 'red' }}>
          {bidData?.totalChange ? formatChange(bidData?.totalChange) : null}
        </span>
      </div>
      <div style={{ fontSize: '9px', color: '#666', marginTop: '4px', textAlign: 'center' }}>
        <a 
          href="https://www.kitco.com/markets/kitco-gold-index"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#666', textDecoration: 'none' }}
        >
          Powered by kitco.com | KGX
        </a>
      </div>
    </div>
  );
};

export default GoldBidWidget;
