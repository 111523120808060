import MetalPriceWidget from "widgets/preciousmetals-price-pefromance-widget (1)";

function MetalPricePage() {

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>Metal Price Performance </div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
    
      <MetalPriceWidget/>

      </div>
    </div>
  );
}

export default MetalPricePage;