import EmbedCode from 'components/EmbedCode/EmbedCode';
import ButtonsSection from 'components/SettingsWidget/ButtonsSection';
import SettingsWidget from 'components/SettingsWidget/SettingsWidget';
import { useSettingsWidgetHandler } from 'hooks/useSettingsWidgetHandler';
import React from 'react';
import GoldChartWidget from 'widgets/GoldChartWidget';
import KGXMiniWidget from 'widgets/KGXMiniWidget';
import KitcoGoldChartWidget from 'widgets/kitco-gold-chart-widget-sample idea2';
import CrossRatesTable from 'widgets/KitcoCrossRatesWidget';
import KitcoFinancialTicker from 'widgets/KitcoFinancialTicker';

function GoldChartPage() {
/*   const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
  } = useSettingsWidgetHandler({ widthDefault: '150' }); */

  const config: WidgetConfig = {
    width: '150px',
    isTransparent: false,
    colorTheme: 'light'
  }
  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>Gold Chart Widget</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
    
      <GoldChartWidget/>

       {/*  <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='400'
          handleTrasparentChecked={handleTrasparentChecked}
        /> */}

{/*         <ButtonsSection
          isTransparentChecked={isTransparentChecked}
          widthValue={widthValue}
          theme={theme}
          widgetType='kgx_mini_widget'
          handleResetSettings={handleResetSettings}
        /> */}

{/*         <EmbedCode
          width={widthValue}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          widgetType='kgx_mini_widget'
        /> */}
      </div>
    </div>
  );
}

export default GoldChartPage;