import ApiService from 'api/apiService';
import React, { useState, useEffect } from 'react';

interface CompactPriceProps {
  config: WidgetConfig, 
}

const CompactGoldPriceWidget = ({config}: CompactPriceProps) => {
  const [goldData, setGoldData] = useState({
    price: 0,
    change: 0,
    changePercent: 0
  });
  const [currentDateTime, setCurrentDateTime] = useState('');

  const getData = async () => {
    try {
      const response = await ApiService.getCertainPM('AU'); 
      const newData = {
        price: response.PreciousMetals.PM.Bid, 
        change: response.PreciousMetals.PM.Change, 
        changePercent: response.PreciousMetals.PM.ChangePercentage, 
      }
      setGoldData(newData); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const now = new Date();
    setCurrentDateTime(now.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/New_York'
    }));
    const interval = setInterval(getData, 100000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`flex items-center bg-gray-100 border border-gray-300 p-1 text-xs ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{ width: config?.width ?? '' }}>
      <div className="bg-red-700 text-white p-1 mr-2 text-center">
        <div>KITCO</div>
        <div>.COM</div>
      </div>
      <div className="flex-grow">
        <div className="text-gray-600">Spot Prices as of {currentDateTime} NY Time</div>
        <div className="flex items-center justify-between mt-1">
          <span className="font-bold">Gold</span>
          <span className="font-bold">{goldData.price.toFixed(2)}</span>
          <span className={`${goldData.change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {goldData.change >= 0 ? '+' : '-'}${Math.abs(goldData.change).toFixed(2)} ({goldData.changePercent.toFixed(3)}%)
          </span>
        </div>
        <div className="text-gray-600 mt-1">Press "RELOAD" for fresh quotes</div>
      </div>
    </div>
  );
};

export default CompactGoldPriceWidget;
