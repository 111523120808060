import ApiService from 'api/apiService';
import React, { useState, useEffect } from 'react';

interface WidgetProps {
  config: WidgetConfig; 
}

interface AssetsI {
  symbol: string; 
  name: string; 
  icon: string; 
  color: string; 
  price: number; 
  change: number; 
  changePercent: number; 
}

interface ItemProps {
  asset: AssetsI; 
}

const assetsDefault = [
  { symbol: 'AU', name: 'Gold', icon: 'Au', color: '#FFD700' },
  { symbol: 'AG', name: 'Silver', icon: 'Ag', color: '#C0C0C0' },
  { symbol: 'PT', name: 'Platinum', icon: 'Pt', color: '#E5E4E2' },
  { symbol: 'PD', name: 'Palladium', icon: 'Pd', color: '#CED0DD' },
  { symbol: 'CL', name: 'Crude Oil', icon: 'Oil', color: '#000000' },
  { symbol: 'BTC', name: 'Bitcoin', icon: '₿', color: '#F7931A' },
  { symbol: 'ETH', name: 'Ethereum', icon: 'Ξ', color: '#627EEA' },
];

const TickerItem = ({ asset }: ItemProps) => {
  const changeClass = asset.change >= 0 ? 'text-green-500' : 'text-red-500';
  const changeSymbol = asset.change >= 0 ? '+' : '-';

  return (
    <div className='inline-flex items-center px-4 py-2 border-r border-gray-200 last:border-r-0 whitespace-nowrap'>
      <div className='flex items-center font-bold mr-2'>
        <div
          className='w-6 h-6 mr-2 rounded-full flex items-center justify-center text-white text-xs font-bold'
          style={{ backgroundColor: asset.color }}
        >
          {asset.icon}
        </div>
        {asset.name}
      </div>
      <span className='font-bold mr-2'>${asset.price}</span>
      <span className={`text-sm ${changeClass}`}>
        {changeSymbol}${Math.abs(asset.change)} ({changeSymbol}
        {Math.abs(asset.changePercent)}%)
      </span>
    </div>
  );
};

const KitcoFinancialTicker = ({config}: WidgetProps) => {
  const [assets, setAssets] = useState<AssetsI[]>([]);


  const getData = async () => {
    try {
      const data = ['AU', 'AG', 'PT', 'PD', 'CL', 'BTC', 'ETH']; 
      const newAssets: any = {}; 
      data.forEach(item => {
        newAssets[item] = {price: '', change: '', changePercent: ''}; 
      }); 
      console.log('newAssets', newAssets); 
      const PMResponse = await ApiService.getPMBaseData(); 
      const energyResponse = await ApiService.getOilBaseData(); 
      const cryptoResponse = await ApiService.getCrypto('BTC,ETH'); 

      PMResponse.PreciousMetals.PM.forEach(item => {
        newAssets[item.Symbol].price = item.Bid; 
        newAssets[item.Symbol].change = item.Change; 
        newAssets[item.Symbol].changePercent = item.ChangePercentage; 
        
      }); 

      const oilData = energyResponse.Values.Value; 
      newAssets[oilData.Symbol].price = oilData.Price; 
      newAssets[oilData.Symbol].change = oilData.Change; 
      newAssets[oilData.Symbol].changePercent = oilData.ChangePercentage; 

      cryptoResponse.results.data_set.forEach(item => {
        newAssets[item.symbol].price = item.rates[0].high.toFixed(2); 
        newAssets[item.symbol].change = item.rates[0].change.toFixed(2);
        newAssets[item.symbol].changePercent = item.rates[0].change_percent.toFixed(2);
      });

      const result = assetsDefault.map(item => ({...item, ...newAssets[item.symbol]})); 
      setAssets(result);
      
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  return (
    <div className={'font-sans overflow-x-auto'} style={{width: config?.width ?? '100%'}}>
      <div className={`w-max border border-gray-200 ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white overflow-x-hidden' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} >
        <div className='flex animate-ticker'>
          {assets.map((asset, idx) => (
            <TickerItem key={asset.symbol} asset={asset} />
          ))}
        </div>
        <div className='flex justify-center items-center py-2 px-4 bg-gray-100 border-t border-gray-200 text-sm font-bold w-full'>
          <a
            href='https://www.kitco.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='flex items-center text-black no-underline'
          >
            <div className='flex items-center mr-2'>
              <span className='bg-yellow-400 text-black px-1 rounded mr-1 text-xs font-bold'>
                K
              </span>
              <span className='text-sm font-bold'>KITCO</span>
            </div>
            Powered by Kitco.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default KitcoFinancialTicker;
