import React, { useState } from 'react';

const MiniKgxBidWidget = () => {
  const [showPercentage, setShowPercentage] = useState(false);
  const [selectedMetal, setSelectedMetal] = useState('gold');

  // Sample data for multiple metals (replace with real-time data in a production environment)
  const metalData: any = {
    gold: {
      price: 2403.90,
      changes: [
        { reason: "USD Weakness", value: 11.40, percentage: 0.47 },
        { reason: "Majority Sellers", value: -50.50, percentage: -2.10 }
      ],
      totalChange: { value: -39.10, percentage: -1.63 }
    },
    silver: {
      price: 28.35,
      changes: [
        { reason: "USD Strength", value: -0.15, percentage: -0.53 },
        { reason: "Industrial Demand", value: 0.30, percentage: 1.07 }
      ],
      totalChange: { value: 0.15, percentage: 0.54 }
    },
    platinum: {
      price: 968.20,
      changes: [
        { reason: "Auto Sector", value: 5.30, percentage: 0.55 },
        { reason: "Supply Constraints", value: 2.80, percentage: 0.29 }
      ],
      totalChange: { value: 8.10, percentage: 0.84 }
    }
  };

  const currentDate = new Date().toLocaleDateString('en-US', { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
  });
  const currentTime = new Date().toLocaleTimeString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: true 
  });

  const formatChange = (change: any) => {
    const value = showPercentage ? change.percentage : change.value;
    const prefix = value >= 0 ? '+' : '';
    return `${prefix}${value.toFixed(2)}${showPercentage ? '%' : ''}`;
  };

  const data = metalData[selectedMetal];

  return (
    <div style={{ 
      fontFamily: 'Arial, sans-serif', 
      width: '250px', 
      border: '1px solid #ccc', 
      borderRadius: '4px', 
      padding: '8px',
      fontSize: '12px'
    }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: '4px'
      }}>
        <select 
          value={selectedMetal} 
          onChange={(e) => setSelectedMetal(e.target.value)}
          style={{
            fontSize: '14px',
            padding: '2px'
          }}
        >
          <option value="gold">Gold</option>
          <option value="silver">Silver</option>
          <option value="platinum">Platinum</option>
        </select>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span style={{ fontWeight: 'bold', fontSize: '16px', marginRight: '4px' }}>{data.price.toFixed(2)}</span>
          <span style={{ fontSize: '8px', color: '#666' }}>Bid</span>
        </div>
      </div>
      <div style={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4px',
        fontSize: '10px',
        color: '#666'
      }}>
        <div style={{ flex: 1 }}></div>
        <span style={{ flex: 2, textAlign: 'center' }}>{currentTime} {currentDate}</span>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <button 
            onClick={() => setShowPercentage(!showPercentage)}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: 'red',
              fontSize: '10px',
              padding: '0'
            }}
          >
            {showPercentage ? '$' : '%'}
          </button>
        </div>
      </div>
      {data.changes.map((change: any, index: number) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
          <span>Chg due to {change.reason}</span>
          <span style={{ color: change.value >= 0 ? 'green' : 'red' }}>
            {formatChange(change)}
          </span>
        </div>
      ))}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        borderTop: '1px solid #ccc', 
        marginTop: '4px', 
        paddingTop: '4px',
        fontWeight: 'bold',
        fontSize: '13px'
      }}>
        <span>Total {selectedMetal.charAt(0).toUpperCase() + selectedMetal.slice(1)} Change:</span>
        <span style={{ color: data.totalChange.value >= 0 ? 'green' : 'red' }}>
          {formatChange(data.totalChange)}
        </span>
      </div>
      <div style={{ fontSize: '9px', color: '#666', marginTop: '4px', textAlign: 'center' }}>
        <a 
          href="https://www.kitco.com/markets/kitco-gold-index"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#666', textDecoration: 'none' }}
        >
          Powered by kitco.com | KGX
        </a>
      </div>
    </div>
  );
};

export default MiniKgxBidWidget;
