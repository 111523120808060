import { listMenu } from 'components/Sidebar/listMenu';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useNavigationSectionHandler() {
  const [activeOption, setActiveOption] = useState<SubOption>();
  const { pathname } = useLocation();

  const handleChangePathname = (path: string): void => {
    if (!listMenu[0].options) return;
    const currentPathOption = listMenu[0].options.find((item) => {
      const optionIdx = item.subOptions.findIndex(
        (el) => path === `/widgets/${el.path}`
      );
      return optionIdx !== -1;
    });
    setActiveOption(currentPathOption);
  };

  useEffect(() => {
    handleChangePathname(pathname);
  }, [pathname]);

  return {
    activeOption,
    pathname
  };
}
