import React, { useState, useEffect } from 'react';
import { RefreshCcw, ChevronDown } from 'lucide-react';

interface KGXMiniWidgetProps {
  config: WidgetConfig, 
}

const instruments = [
  { id: 'gold', name: 'Gold' },
  { id: 'silver', name: 'Silver' },
  { id: 'oil', name: 'Oil' },
  { id: 'bitcoin', name: 'Bitcoin' },
];

const generateRandomData = () => {
  const basePrice = 2000;
  const totalChangePercent = (Math.random() - 0.5) * 0.04; // -2% to 2% change
  const totalChange = basePrice * totalChangePercent;
  const usdChangePercent = (Math.random() - 0.5) * 0.03; // -1.5% to 1.5% change
  const usdChange = basePrice * usdChangePercent;
  const marketChange = totalChange - usdChange;
  const marketChangePercent = (marketChange / basePrice) * 100;
  
  return {
    price: Number((basePrice + totalChange).toFixed(2)),
    usdChange: Number(usdChange.toFixed(2)),
    usdChangePercent: Number(usdChangePercent * 100).toFixed(2),
    marketChange: Number(marketChange.toFixed(2)),
    marketChangePercent: Number(marketChangePercent).toFixed(2),
    totalChange: Number(totalChange.toFixed(2)),
    totalChangePercent: Number(totalChangePercent * 100).toFixed(2),
  };
};

const KGXMiniWidget: React.FC<KGXMiniWidgetProps>  = ({config}) => {
  const [selectedInstrument, setSelectedInstrument] = useState(instruments[0]);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [containerWidth, setContainerWidth] = useState<any>('150'); 
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      const newData: any = generateRandomData();
      setData(newData);
      setIsLoading(false);
    };

    fetchData();
    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, [selectedInstrument]);

  useEffect(() => {
    setIsDarkMode(config.colorTheme === 'dark'); 
    const size = config.width.toString().includes('%') ? '150px' : config.width; 
    setContainerWidth(size); 
  }, [config]); 



  if (isLoading || !data) {
    return <div className="w-[150px] h-[150px] flex items-center justify-center text-xs">Loading...</div>;
  }

  const getChangeColor = (value: any) => value >= 0 ? 'text-green-500' : 'text-red-500';

  return (
    <div className={`p-1 border rounded-lg shadow-md text-gray-800 flex flex-col justify-between text-[9px] ${isDarkMode ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{width: `${containerWidth}`, height: `${containerWidth}`}}>
      <div className="flex justify-between items-center">
        <div className="relative">
          <button 
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="text-[10px] font-semibold flex items-center"
          >
            {selectedInstrument.name} <ChevronDown size={10} className="ml-1" />
          </button>
          {isDropdownOpen && (
            <div className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg">
              {instruments.map((instrument) => (
                <button
                  key={instrument.id}
                  onClick={() => {
                    setSelectedInstrument(instrument);
                    setIsDropdownOpen(false);
                  }}
                  className="block w-full text-left px-2 py-1 text-[9px] hover:bg-gray-100"
                >
                  {instrument.name}
                </button>
              ))}
            </div>
          )}
        </div>
        <RefreshCcw size={10} className={`${isLoading ? 'animate-spin' : ''}`} />
      </div>
      
      <div className="text-center">
        <div className="text-sm font-bold">${data.price.toFixed(2)}</div>
        <div className={`text-[10px] font-semibold ${getChangeColor(data.totalChange)}`}>
          {data.totalChange >= 0 ? '+' : ''}{data.totalChange.toFixed(2)} ({data.totalChangePercent}%)
        </div>
      </div>

      <div className="flex justify-between items-start">
        <div>
          <div className="font-semibold">USD:</div>
          <div className={getChangeColor(data.usdChange)}>
            {data.usdChange >= 0 ? '+' : ''}{data.usdChange.toFixed(2)}
          </div>
          <div className={getChangeColor(data.usdChange)}>
            ({data.usdChangePercent}%)
          </div>
        </div>
        <div>
          <div className="font-semibold">{selectedInstrument.name}:</div>
          <div className={getChangeColor(data.marketChange)}>
            {data.marketChange >= 0 ? '+' : ''}{data.marketChange.toFixed(2)}
          </div>
          <div className={getChangeColor(data.marketChange)}>
            ({data.marketChangePercent}%)
          </div>
        </div>
      </div>

      <div className="text-[8px] text-center text-gray-500">
        <a 
          href="https://www.kitco.com?utm_source=kgx_widget&utm_medium=mini&utm_campaign=price_widget" 
          target="_blank" 
          rel="noopener noreferrer"
          className="hover:underline"
        >
          KGX by: kitco.com
        </a>
      </div>
    </div>
  );
};

export default KGXMiniWidget;