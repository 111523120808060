import EmbedCode from 'components/EmbedCode/EmbedCode';
import ButtonsSection from 'components/SettingsWidget/ButtonsSection';
import SettingsWidget from 'components/SettingsWidget/SettingsWidget';
import { useSettingsWidgetHandler } from 'hooks/useSettingsWidgetHandler';
import React from 'react';
import CommodityWidget from 'widgets/WidgetTableView';

function ComodityWidgetPage() {
  const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
    handleApply,
    config
  } = useSettingsWidgetHandler({ widthDefault: '600' });
  
  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>KGX Table Format</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
        <CommodityWidget config={config}/>

        <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='400'
          handleTrasparentChecked={handleTrasparentChecked}
        />

        <ButtonsSection
          isTransparentChecked={isTransparentChecked}
          widthValue={widthValue}
          theme={theme}
          handleApply={handleApply}
          widgetType='comodity_widget'
          handleResetSettings={handleResetSettings}
        />

        <EmbedCode
          width={widthValue}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          widgetType='comodity_widget'
        />
      </div>
    </div>
  );
}

export default ComodityWidgetPage;
