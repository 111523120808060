import KitcoBrandedMetalPerformance from "widgets/KitcoBrandedMetalPerformance";

function KitcoBrandedMetalPerformancePage() {

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>Kitco Branded Metal Performance</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
        <KitcoBrandedMetalPerformance/>   
      </div>
    </div>
  );
}

export default KitcoBrandedMetalPerformancePage;