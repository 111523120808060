import ApiService from 'api/apiService';
import { shortSymbol } from 'common/constants';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

interface WidgetProps {
  config: WidgetConfig, 
}

const reasonList: any = {
  gold: {
    'down': 'USD Weakness', 
    'up': 'Majority Sellers',  
  },
  silver: {
    'down': 'USD Strength',
    'up': 'Industrial Demand'
  },
  platinum: {
    'down': 'Auto Sector',
    'up': 'Supply Constraints'
  }
}

const MetalBidWidget = ({config}: WidgetProps) => {
  const [selectedMetal, setSelectedMetal] = useState('gold');
  const [metalData, setMetalData] = useState<any>({}); 
  const {width} = useWindowSize();

  const getFormattedData = (data: BaseDataI[]) => {
    const metalDataDefault: any = {
      gold: {},  
      silver: {}, 
      platinum: {}
    }; 
    data.forEach(item => {
      const name = shortSymbol[item.Symbol]; 
      if(metalDataDefault[name]) {
        metalDataDefault[name] = {
          price: item.Bid,
          changes: [
            { reason: reasonList[name].up, value: item.ChangeTrade, percentage: item.ChangePercentTrade },
            { reason: reasonList[name].down, value: item.ChangeUSD, percentage: item.ChangePercentUSD }
          ],
          totalChange: { value: item.Change, percentage: item.ChangePercentage }
        }
      }
    })
    return metalDataDefault; 
  }

  const getData = async () => {
    try {
      const response = await ApiService.getPMBaseData(); 
      const formattedData = getFormattedData(response.PreciousMetals.PM);
      setMetalData(formattedData); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  const currentDate = new Date().toLocaleDateString('en-US', { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
  });
  const currentTime = new Date().toLocaleTimeString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: true 
  });

  const formatChange = (value: number, percentage: number) => {
    const valueStr = `${value >= 0 ? '+' : ''}${value?.toFixed(2)}`;
    const percentStr = `(${percentage >= 0 ? '+' : ''}${percentage?.toFixed(2)}%)`;
    return { valueStr, percentStr };
  };

  const data = metalData[selectedMetal];

  return (
    <div className={`${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{ 
      fontFamily: 'Arial, sans-serif',  
      width: width > 992 ? `${config.width}` : '100%', 
      border: '1px solid #ccc', 
      borderRadius: '4px', 
      padding: '8px',
      fontSize: '12px',
    }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginBottom: '2px'
      }}>
        <select 
          value={selectedMetal} 
          onChange={(e) => setSelectedMetal(e.target.value)}
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '2px',
            border: 'none',
            background: 'transparent'
          }}
        >
          <option value="gold">Gold</option>
          <option value="silver">Silver</option>
          <option value="platinum">Platinum</option>
        </select>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span style={{ fontWeight: 'bold', fontSize: '16px', marginRight: '2px' }}>{data?.price?.toFixed(2)}</span>
          <span style={{ fontSize: '10px', color: '#666' }}>Bid</span>
        </div>
      </div>
      <div style={{ fontSize: '10px', color: '#666', marginBottom: '2px' }}>
        {currentTime} EST {currentDate}
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {data?.changes?.map((change: any, index: number) => {
            const { valueStr, percentStr } = formatChange(change.value, change.percentage);
            return (
              <tr key={index} style={{ borderBottom: '1px solid #eee' }}>
                <td style={{ paddingTop: '2px', paddingBottom: '2px' }}>Chg due to {change.reason}</td>
                <td style={{ textAlign: 'right', color: change.value >= 0 ? 'green' : 'red', paddingLeft: '8px' }}>{valueStr}</td>
                <td style={{ textAlign: 'right', color: change.value >= 0 ? 'green' : 'red', paddingLeft: '8px' }}>{percentStr}</td>
              </tr>
            );
          })}
          <tr style={{ fontWeight: 'bold', borderTop: '2px solid #999' }}>
            <td style={{ paddingTop: '2px', paddingBottom: '2px' }}>Total {selectedMetal.charAt(0).toUpperCase() + selectedMetal.slice(1)} Change:</td>
            <td style={{ textAlign: 'right', color: data?.totalChange?.value >= 0 ? 'green' : 'red', paddingLeft: '8px' }}>
              {formatChange(data?.totalChange.value, data?.totalChange?.percentage).valueStr}
            </td>
            <td style={{ textAlign: 'right', color: data?.totalChange?.value >= 0 ? 'green' : 'red', paddingLeft: '8px' }}>
              {formatChange(data?.totalChange?.value, data?.totalChange?.percentage).percentStr}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: '9px', color: '#666', marginTop: '4px', textAlign: 'center' }}>
        &lt;Powered by kitco.com KGX&gt;
      </div>
    </div>
  );
};

export default MetalBidWidget;
