import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface HookParams {
  widthDefault: string;
}

export function useSettingsWidgetHandler({ widthDefault }: HookParams) {
  const [widthValue, setWidthValue] = useState(widthDefault);
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [isContainerChecked, setIsContainerChecked] = useState(false);
  const [isTransparentChecked, setIsTransparentChecked] = useState(false);
  const [config, setConfig] = useState<WidgetConfig>({
    width: widthDefault.includes('%') ? widthDefault : `${widthDefault}px`,
    isTransparent: false,
    colorTheme: 'light'
  });

  const handleThemeChange = (e: any) => {
    setTheme(e.target.value);
  };

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^-?\d*\.?\d*%?$/.test(inputValue)) {
      setWidthValue(inputValue);
    }
  };

  const handleContainerChecked = (e: any) => {
    if (e.target.checked) {
      setWidthValue('100%');
    } else {
      setWidthValue(widthDefault); 
    }
    setIsContainerChecked(e.target.checked);
  };

  const handleTrasparentChecked = (e: any) => {
    setIsTransparentChecked(e.target.checked);
  };

  const handleApply = () => {
    setConfig({
      width: widthValue.includes('%') ? widthValue : `${widthValue}px`,
      isTransparent: isTransparentChecked,
      colorTheme: theme
    }); 
  }

  const handleResetSettings = () => {
    setWidthValue(widthDefault); 
    setTheme('light'); 
    setIsContainerChecked(false);
    setIsTransparentChecked(false); 
    setConfig({
      width: widthDefault.includes('%') ? widthDefault : `${widthDefault}px`,
      isTransparent: false,
      colorTheme: 'light'
    }); 
  }

/*   useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);  */

  return {
    widthValue,
    theme,
    isContainerChecked,
    isTransparentChecked,
    handleThemeChange,
    handleChange,
    handleContainerChecked,
    handleTrasparentChecked,
    handleResetSettings, 
    handleApply,
    config
  };
}
