import { WidgetsConfigList } from "common/widgetsConfig";
import React from 'react'
import EmbedCode from "components/EmbedCode/EmbedCode";
import ButtonsSection from "components/SettingsWidget/ButtonsSection";
import SettingsWidget from "components/SettingsWidget/SettingsWidget";
import { useSettingsWidgetHandler } from "hooks/useSettingsWidgetHandler";
import FinalKitcoPriceCalculator from "widgets/CalculatorWidget";

function FinalKitcoPriceCalculatorPage () {
  const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
    handleApply,
    config
  } = useSettingsWidgetHandler({ widthDefault: '400' });

  const embedCodeConfig = WidgetsConfigList.calculator; 

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>Final Kitco Price Calculator</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
    
      <FinalKitcoPriceCalculator config={config}/>

      <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='400'
          handleTrasparentChecked={handleTrasparentChecked}
        />

        <ButtonsSection
          isTransparentChecked={isTransparentChecked}
          widthValue={widthValue}
          theme={theme}
          handleApply={handleApply}
          widgetType='comodity_widget'
          embedCodeConfig={embedCodeConfig}
          handleResetSettings={handleResetSettings}
        />

        <EmbedCode
          width={widthValue}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          widgetType='comodity_widget'
          embedCodeConfig={embedCodeConfig}
        />

      </div>
    </div>
  );
}

export default FinalKitcoPriceCalculatorPage;