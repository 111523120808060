import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { ArrowUp, ArrowDown } from 'lucide-react';
import ApiService from 'api/apiService';

const generateChartData = () => {
  const data = [];
  let price = 2460;
  for (let i = 0; i < 100; i++) {
    price += (Math.random() - 0.5) * 10; // More volatile for spikier appearance
    data.push({
      time: i,
      price: parseFloat(price.toFixed(2))
    });
  }
  return data;
};

const GoldChartWidget = () => {
  const [chartData, setChartData] = useState(generateChartData());
  const [currentPrice, setCurrentPrice] = useState(chartData[chartData.length - 1].price);
  const [priceChange, setPriceChange] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());

  const getData = async () => {
    try {
      const response = await ApiService.getHistoryPM(); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    // getData(); 
    const timer = setInterval(() => {
      setCurrentDate(new Date());
      setChartData(prevData => {
        const newData = [...prevData.slice(1)];
        const lastPrice = newData[newData.length - 1].price;
        const newPrice = parseFloat((lastPrice + (Math.random() - 0.5) * 5).toFixed(2));
        newData.push({ time: prevData.length, price: newPrice });
        setCurrentPrice(newPrice);
        setPriceChange(parseFloat((newPrice - lastPrice).toFixed(2)));
        return newData;
      });
    }, 5000); // Update every 5 seconds for more frequent changes

    return () => clearInterval(timer);
  }, []);

  const formatYAxis = (value: number) => value.toFixed(0);

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg w-full">
      <div className="flex justify-between items-center mb-2">
        <div>
          <h2 className="text-xl font-bold text-gray-800">24 Hour Spot Gold (Bid)</h2>
          <p className="text-sm text-gray-600">Spot Kitco Gold (US$/OZ)</p>
        </div>
        <div className="text-right">
          <p className="text-2xl font-bold text-gray-800">{currentPrice.toFixed(2)}</p>
          <p className={`text-sm font-semibold ${priceChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            {priceChange >= 0 ? <ArrowUp className="inline w-4 h-4 mr-1" /> : <ArrowDown className="inline w-4 h-4 mr-1" />}
            {Math.abs(priceChange).toFixed(2)} ({((priceChange / (currentPrice - priceChange)) * 100).toFixed(2)}%)
          </p>
        </div>
      </div>
      <div className="h-64 w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData} margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" hide={true} />
            <YAxis domain={['auto', 'auto']} tickFormatter={formatYAxis} />
            <Tooltip 
              formatter={(value: number) => [`$${value.toFixed(2)}`, 'Price']}
              labelFormatter={(label) => `Time: ${label}`}
            />
            <Line type="linear" dataKey="price" stroke="#FFD700" dot={false} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="flex justify-between text-xs text-gray-500 mt-2">
        <span>NY Time 00:00</span>
        <span>12:00</span>
        <span>23:59</span>
      </div>
      <div className="flex justify-between items-center mt-2">
        <span className="text-sm text-gray-600">
          {currentDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })} {' '}
          {currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })}
        </span>
        <a href="https://www.kitco.com" target="_blank" rel="noopener noreferrer" className="text-sm text-gray-600 hover:text-gray-800">
          Powered by Kitco.com
        </a>
      </div>
    </div>
  );
};

export default GoldChartWidget;
