import { ChevronLeft } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { listMenu } from './listMenu';
import MenuItem from './MenuItem';

interface PropsI {
  isOpen: boolean; 
  handleClose: () => void; 
}

function MainMenu({isOpen, handleClose}: PropsI) {
  const[heightSection, setHeightSection] = useState(0);  
  const {height} = useWindowSize();
  const menuRef = useRef<any>(null);
  useOnClickOutside(menuRef, handleClose);

  useEffect(() => {
    const result = menuRef?.current?.clientHeight; 
    setHeightSection(result); 
  }, [menuRef?.current, height]);


  return (
    <div ref={menuRef} className={`fixed h-full lg:pt-[65px] w-[250px] border-r border-r-1 border-gray-300 z-50 lg:z-20 bg-gray-50 transform lg:translate-x-0 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out`}>
      <div className='lg:hidden h-[65px] border-b border-gray-300 flex items-center justify-end pr-3'>
        <div className='bg-gray-200 rounded-md p-[5px]' onClick={handleClose}> 
          <ChevronLeft size={20}/>
        </div>
         
        </div>    
      <div className={`w-full overflow-y-auto pt-2`} style={{
        height: `${heightSection - 80}px`
      }}>
        {listMenu.map((option, idx) => <MenuItem key={idx} option={option}/>)}
      </div>
    </div>
  );
}

export default MainMenu;
