import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

interface RequestOptions extends AxiosRequestConfig {
  retry?: number;
}

interface ApiResponse<T> extends AxiosResponse {
  data: T;
}

interface ApiError {
  message: string;
}
const BASE_URL = 'https://proxy.kitco.com'; 
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // timeout: 5000,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  // withCredentials: true,
});


export const api = {
    get: async <T>(
      url: string,
      params: any = {},
      options: RequestOptions = {}
    ): Promise<T> => {
      try {
        const response = await axiosInstance.get<T>(url, { params, ...options });
        return response.data;
      } catch (error: any) {
        throw new Error(error);    
      }
    },
    post: async <T>(
      url: string,
      data: any = {},
      options: RequestOptions = {}
    ): Promise<T> => {
      try {
        const response = await axiosInstance.post<T>(url, data, options);
        return response.data;
      } catch (error: any) {
        throw new Error(error);    
      }
    }
  };