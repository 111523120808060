import EmbedCode from 'components/EmbedCode/EmbedCode';
import ButtonsSection from 'components/SettingsWidget/ButtonsSection';
import SettingsWidget from 'components/SettingsWidget/SettingsWidget';
import { useSettingsWidgetHandler } from 'hooks/useSettingsWidgetHandler';
import MetalBidWidget from 'widgets/allmetals-mini-expanded-bid-widget';

function MetalBidWidgetPage() {
  const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
    handleApply,
    config
  } = useSettingsWidgetHandler({ widthDefault: '340' });

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] font-bold pb-4'>Metal Bid Widget</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>

       <MetalBidWidget config={config}/> 
    
        <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='400'
          handleTrasparentChecked={handleTrasparentChecked}
        /> 

        <ButtonsSection
          isTransparentChecked={isTransparentChecked}
          widthValue={widthValue}
          theme={theme}
          widgetType='metal_bid_widget'
          handleResetSettings={handleResetSettings} 
          handleApply={handleApply}        
        /> 

          <EmbedCode
            width={widthValue}
            isTransparent={isTransparentChecked}
            colorTheme={theme}
            widgetType='metal_bid_widget'
        /> 
      </div>
    </div>
  );
}

export default MetalBidWidgetPage;