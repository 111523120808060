import ApiService from 'api/apiService';
import { shortSymbol } from 'common/constants';
import React, { useState, useEffect } from 'react';

interface SpotPriceProps {
  config: WidgetConfig, 
}


const SpotPriceWidget = ({config}: SpotPriceProps) => {
  const [prices, setPrices] = useState<any>(null);
  const [currentDateTime, setCurrentDateTime] = useState('');

  const getData = async () => {
    try {
      const data: any = { gold: {}, silver: {}, platinum: {}, palladium: {}}; 
      const resultPM = await ApiService.getPMBaseData(); 
      resultPM.PreciousMetals.PM.forEach(item => {
        data[shortSymbol[item.Symbol]] = {price: item.Bid, change: item.Change, changePercent: item.ChangePercentage, high: item.High, low: item.Low}; 
      });  
      setPrices(data); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const now = new Date();
    setCurrentDateTime(now.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/New_York'
    }) + ' New York Time');
    const interval = setInterval(getData, 100000); 
    return () => clearInterval(interval);
  }, []);

  const PriceRow = ({ metal, data }: any) => (
    <tr className="border-b text-xs">
      <td className="py-2 pr-1 font-semibold">{metal}</td>
      <td className="py-2 px-1 text-right">
        <div>{data?.price?.toFixed(2)}</div>
        <div className={`text-[10px] ${data.change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {data?.change >= 0 ? '▲' : '▼'}{Math.abs(data?.change)?.toFixed(2)} ({data?.changePercent?.toFixed(2)}%)
        </div>
      </td>
      <td className="py-2 px-1 text-right">{data.high.toFixed(2)}</td>
      <td className="py-2 px-1 text-right">{data.low.toFixed(2)}</td>
    </tr>
  );

  return (
    <div className={`h-[250px] p-3 flex flex-col justify-between shadow-lg ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-100 text-gray-800'}`} style={{width: config?.width ?? ''}}>
      <div>
        <div className="mb-2 text-xs text-gray-600">{currentDateTime}</div>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200 text-xs">
              <th className="py-1 pr-1 text-left">Metal</th>
              <th className="py-1 px-1 text-right">Price</th>
              <th className="py-1 px-1 text-right">High</th>
              <th className="py-1 px-1 text-right">Low</th>
            </tr>
          </thead>
          {prices && <tbody>
            <PriceRow metal="Gold" data={prices?.gold} />
            <PriceRow metal="Silver" data={prices?.silver} />
            <PriceRow metal="Platinum" data={prices?.platinum} />
            <PriceRow metal="Palladium" data={prices?.palladium} />
          </tbody>}
        </table>
      </div>
      <div className="text-center text-xs text-gray-600">
        Powered by <a href="https://www.kitco.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Kitco.com</a>
      </div>
    </div>
  );
};

export default SpotPriceWidget;
